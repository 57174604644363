import React, { useState, useEffect, useRef } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Box, Button, Container, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { NavigationSection } from './NavigationSection';
import SimCardAlertOutlinedIcon from '@mui/icons-material/SimCardAlertOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import vector from '../../Images/Vector.svg'
import Grid from '@mui/material/Grid';
import PersonalDetails from './PersonalDetails'
import FourCs from './FourCs'
import axios from 'axios'
import SoluationHeader from './SoluationsHeader';
import { useAppSelector, useAppDispatch } from '../../redux/hook'
import FinancialObjectives from './FinancialObjectives'
import { fetchCountry, fetchAgencies, fetchPortFolioGrowthRate, fetchCountryDialCode, fetchRateVisecountryList, fetchCountryRate, fetchRelations, fetchIncomeSource, fetchIncomeExpense, fetchIncomeSavings } from '../../redux/GenericDetailsSlice'
import FourRisksComponent from './FourRisksComponent';
import PortfolioAssets from './PortfolioAssets';
import RiskProfiler from './RiskProfiler'
import Budget from './Budget'
import GapAnalysis from './GapAnalysis'
import { useNavigate, useLocation } from 'react-router-dom'
import SummaryTabs from './SummaryTabs'
import ProductDetails from './ProductDetails'
import { useSelector } from 'react-redux';

const SolutionDashboard = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const isClosedSessionValue = sessionStorage.getItem('closedSession') === 'true'
    const [formId, setFormId] = useState(0)
    const location = useLocation();
    const navigate = useNavigate()
    const [details, setDetails] = React.useState<any>()
    const [totalAmount, setTotalAmount] = React.useState<any>()
    const [totalBudgetAmount, setTotalBudgetAmount] = React.useState<any>()
    const [userName, setUserName] = React.useState<string>()
    const [shouldNavigate, setShouldNavigate] = React.useState<boolean>(false)
    const [selectedCurrency, setSelectedCurrency] = React.useState<string>('USD')
    const [isClosedSession, setIsClosedSession] = React.useState((location?.state?.closedSession || isClosedSessionValue))
    const [isAnnualReviewSession, setIsAnnualReviewSession] = React.useState(location?.state?.annualReviewSession ? location?.state?.annualReviewSession : false)
    const [isFormSubmited, setIsFormSubmited] = React.useState(false)
    const [selectedTab, setSelectedTab] = React.useState('')
    const [isQuoteGenerated, setIsQuoteGenerated] = React.useState(false)
    const [generateSoluation, setGenerateSoluation] = React.useState(false)
    const [isReGenerateSoluation, setIsReGenerateSoluation] = React.useState<boolean | null>(false)
    const [selectedRisk, setSelectedRisk] = React.useState('')
    const [sessionDetail,setSessionDetails] = React.useState({})
    
    const _setPageID = (id: number) => {
        setFormId(id)
    }
    const clientConsetDetail = useSelector((state) => state)
    //.customerDetails?.customerDetailsObject?.applicationStatus?.clientConsent)
    const submitRef = useRef<HTMLButtonElement>(null);
    // const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const bearerToken = localStorage.getItem('bearerToken')
    const headers = {
        Authorization: `Bearer ${bearerToken}`,
    };
    const requestOptions = {
        headers: headers,
    };
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchCountry());
        dispatch(fetchCountryDialCode());
        dispatch(fetchPortFolioGrowthRate());
        dispatch(fetchRateVisecountryList());
        dispatch(fetchCountryRate());
        dispatch(fetchRelations());
        dispatch(fetchIncomeSource());
        dispatch(fetchIncomeExpense());
        dispatch(fetchIncomeSavings());
        dispatch(fetchAgencies());
    }, [])



    const _setProductDetails = (details: any, totalAmount: any, totalBudgetAmount: any, userName: string, selectedSolution: string, selectedRiskDetails: string) => {
        setDetails(details)
        setTotalAmount(totalAmount)
        setTotalBudgetAmount(totalBudgetAmount)
        setUserName(userName)
        setSelectedTab(selectedSolution)
        setSelectedRisk(selectedRiskDetails)
    }
    const _SubmitForm = () => {
        navigate('/dashboard', { replace: true })
    }
    const _navigateToDashboard = (flag: boolean) => {
        setShouldNavigate(flag)
    }
    const regenerateSoluation = () => {
        setIsReGenerateSoluation(true)
        _setPageID(8)
    }
    const updateParentComponent = async () => {
        const response = await fetch(
            baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
        );
        const responseDetails = await response.json();
        setSessionDetails(responseDetails)
        setIsQuoteGenerated(responseDetails?.isQuoteGenerated)
    }
    useEffect(() => {
        updateParentComponent()
    }, [formId])
    return (
        <>
            <Grid container>
                <SoluationHeader formId={formId} setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency} />
            </Grid>
            <Container style={{ marginTop: '40px', marginBottom: '40px', padding: '15px' }}>
                <Grid container sx={{ textAlign: 'left' }}>
                    <Grid item xs={2}>
                        <Button className="reGenerateButton" disabled={isClosedSession === true || isQuoteGenerated || generateSoluation === false} onClick={() => regenerateSoluation()} variant="outlined" sx={{ padding: '15px 10px' }}> <img src={vector} alt='generate solution' /> &nbsp;  Generate Solution</Button>
                    </Grid>
                    <Grid item xs={9}>
                        <Button disabled={isClosedSession === true || isQuoteGenerated} variant="text" sx={{ padding: '15px 10px ' }} startIcon={<SimCardAlertOutlinedIcon />}>Session Feedback</Button>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: 'end' }} onClick={_SubmitForm}>
                        <IconButton aria-label="delete" sx={{ color: '#FF7B6F' }} style={{ color: '#FF7B6F', marginLeft: 'auto' }}>
                            <CancelOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12}>
                        <NavigationSection setIsReGenerateSoluation={setIsReGenerateSoluation} setGenerateSoluation={setGenerateSoluation} isAnnualReviewSession={isAnnualReviewSession} formId={formId} _setPageID={_setPageID} />
                    </Grid>
                    <Grid item xs={12}>
                        {formId === 0 && <PersonalDetails setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} isClosedSession={isClosedSession} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} updateParentComponent={updateParentComponent} />}
                        {formId === 1 && <FourCs setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} isClosedSession={isClosedSession} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 2 && <FourRisksComponent setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} isClosedSession={isClosedSession} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 3 && <FinancialObjectives setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} isClosedSession={isClosedSession} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 4 && <PortfolioAssets setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} isClosedSession={isClosedSession} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 5 && <RiskProfiler setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} isClosedSession={isClosedSession} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 6 && <Budget setIsFormSubmited={setIsFormSubmited} isQuoteGenerated={isQuoteGenerated} submitRef={submitRef} isClosedSession={isClosedSession} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 7 && <GapAnalysis isClosedSession={isClosedSession} isQuoteGenerated={isQuoteGenerated} selectedCurrency={selectedCurrency} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 8 && <SummaryTabs isReGenerateSoluation={isReGenerateSoluation} isQuoteGenerated={isQuoteGenerated} isFormSubmited={isFormSubmited} isClosedSession={isClosedSession} selectedCurrency={selectedCurrency} _setProductDetails={_setProductDetails} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} />}
                        {formId === 9 && <ProductDetails sessionDetail={sessionDetail}isFormSubmited={isFormSubmited} isQuoteGenerated={isQuoteGenerated} selectedTab={selectedTab} isClosedSession={isClosedSession} selectedCurrency={selectedCurrency} userName={userName} totalAmount={totalAmount} details={details} totalBudgetAmount={totalBudgetAmount} submitRef={submitRef} _setPageID={_setPageID} _navigateToDashboard={_navigateToDashboard} selectedRisk={selectedRisk} />}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default SolutionDashboard