import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs, { Dayjs } from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios'
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DevTool } from "@hookform/devtools";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {validateInput,sanitizeInputValue} from '../CommonComponent/ValidateInput'

type FormValues = {
  sessionId: string,
  incomeSources: { 'id': string, "incomeSourceId": number | undefined, "percentage": number | undefined }[],
  expenses: {
    'id': string, "expenseId": number | undefined, "percentage": number | undefined, "description": string,
    "targetYear": any,
    "tenureRemainingInMonths": number | undefined
  }[],
  savings: { 'id': string, "savingOptionId": number | undefined, "percentage": number | undefined }[],
  incomeContinuation: { "que": string, 'value': string }[],
}
export const FourCs = ({ setIsFormSubmited, isClosedSession, isQuoteGenerated, _setPageID, _navigateToDashboard, submitRef }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const incomeSourceList = useSelector((state: any) => state.genericDetails?.incomeSourceList);
  const incomeExpenseList = useSelector((state: any) => state.genericDetails?.incomeExpenseList);
  const incomeSavingsList = useSelector((state: any) => state.genericDetails?.incomeSavingsList);
  const [isJointApplicant, setIsJointApplicant] = React.useState(false)
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [details, setDetails] = React.useState<any>()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [incomeSourcesError, setIncomeSourcesError] = React.useState<boolean>(false)
  const [expensesError, setExpensesError] = React.useState<boolean>(false)
  const [savingsError, setSavingsError] = React.useState<boolean>(false)
  const [incomeContinuationError, setIncomeContinuationError] = React.useState<boolean>(false)

  const _getYear = (value: any) => {
    let year = 0
    if (value !== '' || value !== undefined) {
      year = new Date(value).getFullYear();
    }
    return year;
  }

  const formatedDate = (value: any) => {
    if (!value || value.$d === 'Invalid Date' || value === 1970) {
      return null;
    }
    if (value.$d !== 'Invalid Date' && value.$d !== undefined) {
      const date = new Date(value.$d);
      return dayjs(date);
    }
    const date = new Date(isNaN(value) ? value : `10-02-${value}`);
    return dayjs(date);
  }
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const responseDetails = await response.json();
      const data = responseDetails?.fourC
      setDetails(responseDetails);
      let totalExpense = 0;
      responseDetails?.fourC?.expenses?.forEach((element: any) => {
        totalExpense = Number(totalExpense) + Number(element?.percentage);
      })
      if (totalExpense > 80 && totalExpense < 100) {
        setExpenseErrorMsg('Total is currently more than 80% of your Income Consumption');
        //setIsExpenseError(true)
      }
      (responseDetails?.personalDetails?.coApplicant !== null) ? setIsJointApplicant(true) : setIsJointApplicant(false)
      setShowSpinner(false)
      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        incomeSources: data.incomeSources.length > 0 ? data.incomeSources : [{
          "id": '',
          "incomeSourceId": undefined,
          "percentage": undefined
        }],
        expenses: data.expenses.length > 0 ? data.expenses : [{
          "id": '',
          "expenseId": undefined,
          "percentage": undefined,
          "description": '',
          "targetYear": '',
          "tenureRemainingInMonths": undefined
        }],
        savings: data.savings.length > 0 ? data.savings : [{
          'id': '',
          "savingOptionId": undefined,
          "percentage": undefined
        }],
        incomeContinuation: data?.incomeContinuation !== undefined ? data?.incomeContinuation?.value : [
          { "que": "q1", 'value': '' },
          { "que": "q2", 'value': '' },
          { "que": "q3", 'value': '' }
        ],
      }
    },
    mode: 'all'
  });
  const { register, control, handleSubmit, reset, formState, formState: { errors, touchedFields }, watch } = form
  const { fields, append, remove } = useFieldArray({
    name: 'incomeSources',
    control
  })

  const useFieldArrayDetails1 = useFieldArray({
    name: 'expenses',
    control
  })
  const fields1 = useFieldArrayDetails1.fields
  const append1 = useFieldArrayDetails1.append
  const remove1 = useFieldArrayDetails1.remove

  const useFieldArrayDetails2 = useFieldArray({
    name: 'savings',
    control
  })
  const fields2 = useFieldArrayDetails2.fields
  const append2 = useFieldArrayDetails2.append
  const remove2 = useFieldArrayDetails2.remove
  const [totalIncomeError, setTotalIncomeError] = React.useState(false)
  const [expenseErrorMsg, setExpenseErrorMsg] = React.useState<string | undefined>()
  const [isExpenseError, setIsExpenseError] = React.useState(false)
  let fcdetails = watch()
  useEffect(() => {
    let status = details?.progress?.fourC?.status
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {
    errors.hasOwnProperty('incomeSources') || totalIncomeError ? setIncomeSourcesError(true) : setIncomeSourcesError(false)
    errors.hasOwnProperty('expenses') || isExpenseError ? setExpensesError(true) : setExpensesError(false)
    errors.hasOwnProperty('savings') ? setSavingsError(true) : setSavingsError(false)
    errors.hasOwnProperty('incomeContinuation') ? setIncomeContinuationError(true) : setIncomeContinuationError(false)
    //setIsError('#FF7B6F')
  }
  React.useEffect(() => {
    if (touchedFields?.incomeSources) {
      let totalIncome = 0;
      fcdetails?.incomeSources?.forEach((element: any) => {
        totalIncome = Number(totalIncome) + Number(element?.percentage);
      })
      if (totalIncome > 100) {

        setTotalIncomeError(true);
        //setIncomeSourcesError(true)
      } else {
        //setIsError('#b8b8b8')
        setTotalIncomeError(false);
        //setIncomeSourcesError(false)
      }
    }
    if (touchedFields?.expenses) {
      let totalExpense = 0;
      fcdetails?.expenses?.forEach((element: any) => {
        totalExpense = Number(totalExpense) + Number(element?.percentage);
      })
      if (totalExpense > 80 && totalExpense < 100) {
        setExpenseErrorMsg('Total is currently more than 80% of your Income Consumption');
        //setIsExpenseError(true)
      } else if (totalExpense > 99) {

        setExpenseErrorMsg('Total percentage cannot be more than or equal of 100%');
        setIsExpenseError(true)
      }
      else {
        //setIsError('#23AA01')
        setExpenseErrorMsg('');
        setIsExpenseError(false)
      }
    }
  }, [fcdetails])

  const _onSubmit = (data: FormValues, action: string) => {
    if (totalIncomeError === false && isExpenseError === false) {
      let reponse = { ...data }
      let incomeSources = reponse.incomeSources?.map((element: any) => {
        element.id === '' && delete element.id
        delete element.customerId
        return element
      })
      let savings = reponse.savings?.map((element: any) => {
        element.id === '' && delete element.id
        delete element.customerId
        return element
      })
      let expenses = reponse.expenses?.map((element: any) => {
        let detailsObject = { ...element }
        detailsObject.id === '' && delete detailsObject.id
        delete detailsObject.customerId
        detailsObject.targetYear = _getYear(element?.targetYear)
        return detailsObject
      })
      let incomeContinuation = {}
      let incomeContinuationarray = [
        { "que": "q1", 'value': reponse?.incomeContinuation[0].value },
        { "que": "q2", 'value': reponse?.incomeContinuation[1].value },
        { "que": "q3", 'value': reponse?.incomeContinuation[2].value }]
      if (details?.fourC?.incomeContinuation?.length > 0) {
        incomeContinuation = { 'id': details?.incomeContinuation[0]?.id, 'value': incomeContinuationarray }
      } else {
        incomeContinuation = { 'value': incomeContinuationarray }
      }
      let payload = { ...reponse, incomeContinuation, incomeSources, savings, expenses }
      setFourCsDetails(payload, action)
    } else {
      _onError()
    }
  }
  const setFourCsDetails = async (fourCsDetails: {}, action: string) => {
    const response = await axios.post(baseUrl + '/customers/fourc', fourCsDetails, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        if (action === 'next') {
          _setPageID(2)
        } else if (action === 'dashboard') {
          _navigateToDashboard(true);
        } else {
          _setPageID(0)
        }
        if (formState.isSubmitSuccessful) {
          reset();
        }
      }).catch(error => {
        if (error.response.status === 401) {
          navigate('/login');
        } else {
          toast.error('Something went wrong, please try again later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }
  const _deleteIncomeSources = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/income-source?id=' + id, requestOptions)
        .then((response) => { remove(index) })
    } else {
      remove(index)
    }
  }
  const _deleteExpense = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/expense?id=' + id, requestOptions)
        .then((response) => { remove1(index) })
    } else {
      remove1(index)
    }
  }
  const _deleteSavings = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/saving?id=' + id, requestOptions)
        .then((response) => { remove2(index) })
    } else {
      remove2(index)
    }
  }
  return (
    <Box sx={{ flexGrow: 1, margin: '0', justifyContent: 'space-between' }}>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Box>
        <h3 style={{ color: '#012169', marginTop: '30px', textAlign: 'left', marginBottom: '40px' }}>For {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</h3>
      </Box>
      <Grid container>
        {/* forms start */}
        <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
          <Grid item xs={12}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: incomeSourcesError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="accordion-summary"
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ margin: '0' }}
              >
                <Typography sx={{ padding: '0 16px' }}>{t("FourCsCreatioIncome")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                {totalIncomeError && <FormHelperText sx={{ color: '#ea635c' }}>{t("FourCsTotalPercentageCheck")}</FormHelperText>}
                {
                  fields.map((field, index) => {
                    return <Grid key={field.id} container sx={{ textAlign: 'left', padding: '12px 16px' }}>
                      <Grid item xs={6}>

                        <FormControl variant="standard" className="formElement">
                          <InputLabel id="demo-simple-select-filled-label">{t("FourCsSourceIncome")}</InputLabel>
                          {(isJointApplicant === true) ?
                            <Controller
                              control={control}
                              defaultValue={"" as any}
                              {...register(`incomeSources.${index}.incomeSourceId` as const, {
                                required: "Income Source is required",
                                validate: {
                                  sanatizeInput: (fieldValue) => {
                                    return validateInput(fieldValue) || 'Please provide valid input'
                                  }
                                }
                              })}
                              render={({ field }) => {
                                const { ref } = field;
                                return (<Select
                                  label="Income Source"
                                  type="number"
                                  onWheel={event => (document.activeElement as HTMLElement).blur()}
                                  id="incomeSource"
                                  error={!!errors?.incomeSources?.[index]?.incomeSourceId}
                                  {...field}
                                >
                                  <MenuItem key={1} value={1}>Salary - Life 1</MenuItem>
                                  <MenuItem key={2} value={6}>Salary - Life 2</MenuItem>
                                  <MenuItem key={3} value={4}>Business Income - Life 1</MenuItem>
                                  <MenuItem key={4} value={7}>Business Income - Life 2</MenuItem>
                                  <MenuItem key={5} value={2}>Rental</MenuItem>
                                  <MenuItem key={6} value={3}>Investments</MenuItem>
                                  <MenuItem key={7} value={5}>Others</MenuItem>
                                </Select>)
                              }} />
                            : <Controller
                              control={control}
                              defaultValue={"" as any}
                              {...register(`incomeSources.${index}.incomeSourceId` as const, {
                                required: "Income Source is required",
                                validate: {
                                  sanatizeInput: (fieldValue) => {
                                    return validateInput(fieldValue) || 'Please provide valid input'
                                  }
                                }
                              })}
                              render={({ field }) => (<Select
                                label="Income Source"
                                type="number"
                                onWheel={event => (document.activeElement as HTMLElement).blur()}
                                id="incomeSource"
                                error={!!errors?.incomeSources?.[index]?.incomeSourceId}
                                {...field}
                              >
                                {incomeSourceList ?
                                  incomeSourceList.map((listElement: any, index: number) => {
                                    if (listElement.id !== 6 && listElement.id !== 7) {
                                      return <MenuItem key={index} value={listElement.id}>{listElement.name}</MenuItem>
                                    }
                                  }) : ''}
                              </Select>)} />
                          }

                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.incomeSources?.[index]?.incomeSourceId?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="standard"
                          className="formElement"
                          label={t("FinanceAdvicePercentage")}
                          type="number"
                          onWheel={event => (document.activeElement as HTMLElement).blur()}
                          id="percentage"
                          {...register(`incomeSources.${index}.percentage` as const, {
                            required: "Income percentage required",
                            validate: {
                              sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                              }
                            },
                            pattern: {
                              value: /^\d+\.?\d{0,2}$/,
                              message: 'Please Enter valid decimal value.'
                            },
                            maxLength: {
                              value: 5,
                              message: "You have exceeded the character limit."
                            },
                            max: {
                              value: 100,
                              message: "Please enter valid income percentage"
                            },
                            min: {
                              value: 0,
                              message: "Please enter valid income percentage"
                            }
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">%</InputAdornment>
                            ),
                          }}
                          error={!!errors?.incomeSources?.[index]?.percentage}
                          helperText={errors?.incomeSources?.[index]?.percentage?.message}
                        />
                        {
                          (index > 0) &&
                          < IconButton aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => _deleteIncomeSources(index, fcdetails?.incomeSources[index]?.id)}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                  })
                }
                {fields?.length < 8 && <Grid item xs={6} sx={{ marginTop: '15px', textAlign: 'left', padding: '12px 16px' }}>
                  <Button variant="outlined" onClick={() => append({ 'id': '', "incomeSourceId": undefined, "percentage": undefined })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                    {t("FourCsAddIncome")}
                  </Button>
                </Grid>}
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* accordion 2 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: expensesError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="accordion-summary"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ padding: '0 16px' }}>{t("FourCsConsumptionIncome")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                {expenseErrorMsg && <FormHelperText sx={{ color: '#ea635c' }}>{expenseErrorMsg}</FormHelperText>}
                {
                  fields1.map((field, index) => {
                    return <Grid key={field.id} container sx={{ textAlign: 'left', padding: '12px 16px' }}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement">
                          <InputLabel id="demo-simple-select-filled-label">{t("FourCsExpenseCategory")}</InputLabel>
                          <Controller
                            control={control}
                            defaultValue={"" as any}
                            {...register(`expenses.${index}.expenseId` as const, {
                              required: "Expense Category is required",
                              validate: {
                                sanatizeInput: (fieldValue) => {
                                  return validateInput(fieldValue) || 'Please provide valid input'
                                }
                              }
                            })}
                            render={({ field }) => {
                              const { ref } = field;
                              return (<Select
                                label="Expense Category"
                                type="number"
                                onWheel={event => (document.activeElement as HTMLElement).blur()}
                                id="expenseId"
                                error={!!errors?.expenses?.[index]?.expenseId}
                                {...field}
                              >
                                {incomeExpenseList ?
                                  incomeExpenseList.map((listElement: any, index: number) => {
                                    return <MenuItem key={index} value={listElement.id}>{listElement.name}</MenuItem>
                                  }) : ''}
                              </Select>)
                            }} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.expenses?.[index]?.expenseId?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="standard"
                          className="formElement"
                          label={t("FinanceAdvicePercentage")}
                          type="number"
                          onWheel={event => (document.activeElement as HTMLElement).blur()}
                          id="percentage"
                          {...register(`expenses.${index}.percentage` as const, {
                            required: "expense percentage required",
                            validate: {
                              sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                              }
                            },
                            pattern: {
                              value: /^\d+\.?\d{0,2}$/,
                              message: 'Please Enter valid decimal value.'
                            },
                            maxLength: {
                              value: 5,
                              message: "You have exceeded the character limit."
                            },
                            max: {
                              value: 100,
                              message: "Please enter valid expenses percentage"
                            },
                            min: {
                              value: 0,
                              message: "Please enter valid expenses percentage"
                            }
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">%</InputAdornment>
                            ),
                          }}
                          error={!!errors?.expenses?.[index]?.percentage}
                          helperText={errors?.expenses?.[index]?.percentage?.message}
                        />
                        {
                          (index > 0) &&
                          <IconButton aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => _deleteExpense(index,fcdetails?.expenses[index]?.id)}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      </Grid>
                      {(fcdetails?.expenses?.[index]?.expenseId === 3) &&
                        <Grid item xs={6} className="padding-top">
                          <TextField
                            variant="standard"
                            className="formElement"
                            label="Description"
                            type="string"
                            id="description"
                            {...register(`expenses.${index}.description` as const, {
                              validate: {
                              sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                              }
                            }
                            })}
                            error={!!errors?.expenses?.[index]?.description}
                            helperText={errors?.expenses?.[index]?.description?.message}
                          />
                        </Grid>
                      }
                      {(fcdetails?.expenses?.[index]?.expenseId === 3) &&
                        <Grid item xs={6} className="padding-top">
                          <FormControl variant="standard" className="formElement">
                            <Controller
                              control={control}
                              {...register(`expenses.${index}.targetYear`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    return (
                                      (fieldValue?.$d != 'Invalid Date' && fieldValue?.$d !== '' && fieldValue !== undefined) || 'Please enter a valid date'
                                    )
                                  },
                                  noFutureDate: (fieldValue) => {
                                    let currentDate = (new Date().getFullYear()) - 1;
                                    if (fieldValue > currentDate) {
                                      let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                                      let enteredDate = (typeof dt === 'number') ? dt : new Date(dt).getFullYear()
                                      return (
                                        (enteredDate > currentDate) || 'Please enter a future date'
                                      )
                                    }
                                  }
                                }
                              })
                              }
                              render={({ field }) => {
                                const { ref } = field;
                                return (<LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    onChange={(e) => field.onChange(e)}
                                    value={formatedDate(field.value ? field.value : '')}
                                    minDate={formatedDate('01/01/2023')}
                                    maxDate={formatedDate('12/31/2073')}
                                    label={(fcdetails?.expenses?.[index]?.expenseId === 3) ? 'Last year of pre-university schooling' : 'When do you need it?'} views={['year']} slotProps={{ textField: { variant: 'standard' } }} />
                                </LocalizationProvider>)
                              }} />
                            <FormHelperText sx={{ color: '#ea635c' }}>{errors?.expenses?.[index]?.targetYear?.message?.toString()}</FormHelperText>
                          </FormControl>
                        </Grid>
                      }
                      {
                        (fcdetails?.expenses?.[index]?.expenseId === 6 || fcdetails?.expenses?.[index]?.expenseId === 7) &&
                        <Grid item xs={6} className="padding-top">
                          <TextField
                            variant="standard"
                            className="formElement"
                            label="Remaining loan tenure"
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            id="tenureRemainingInMonths"
                            {...register(`expenses.${index}.tenureRemainingInMonths` as const, {
                              required: "loan tenure is required",
                              validate: {
                                sanatizeInput: (fieldValue) => {
                                  return validateInput(fieldValue) || 'Please provide valid input'
                                }
                              },
                              pattern: {
                                value: /^\d{1,8}(\.\d{0,2})?$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 8,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 10000000,
                                message: "Please enter valid value."
                              },
                              min: {
                                value: 0,
                                message: "Please enter valid value."
                              }
                            })}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">{t("FinanceAdviceMonths")}</InputAdornment>
                              ),
                            }}
                            error={!!errors?.expenses?.[index]?.tenureRemainingInMonths}
                            helperText={errors?.expenses?.[index]?.tenureRemainingInMonths?.message}
                          />
                        </Grid>
                      }

                    </Grid>
                  })
                }
                {fields1?.length < 8 && <Grid item xs={6} sx={{ marginTop: '15px', textAlign: 'left', padding: '12px 16px' }}>
                  <Button variant="outlined" onClick={() => append1({ 'id': '', "expenseId": undefined, "percentage": undefined, "description": '', 'targetYear': '', 'tenureRemainingInMonths': undefined })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                    {t("FourCsAddExpense")}
                  </Button>
                </Grid>}
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 2*/}
          {/* accordion 3 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: savingsError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                className="accordion-summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ padding: '0 16px' }}>{t("FourCsConservtionIncome")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                {
                  fields2.map((field, index) => {
                    return <Grid key={field.id} container sx={{ textAlign: 'left', padding: '12px 16px' }}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement">
                          <InputLabel id="investment-label">{t("FourCsInvestmentSavings")}</InputLabel>
                          <Controller
                            control={control}
                            defaultValue={"" as any}
                            {...register(`savings.${index}.savingOptionId` as const, {
                              required: "Savings Category is required"
                            })}
                            render={({ field }) => {
                              const { ref } = field;
                              return (<Select
                                label="Investment/Savings"
                                type="number"
                                onWheel={event => (document.activeElement as HTMLElement).blur()}
                                id="savingOptionId"
                                error={!!errors?.savings?.[index]?.savingOptionId}
                                {...field}
                              >
                                {incomeSavingsList ?
                                  incomeSavingsList.map((listElement: any, index: number) => {
                                    if (listElement.id !== 6 && listElement.id !== 7) {
                                      return <MenuItem key={index} value={listElement.id}>{listElement.name}</MenuItem>
                                    }
                                  }) : ''}
                              </Select>)
                            }} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.savings?.[index]?.savingOptionId?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="standard"
                          className="formElement"
                          label={t("FinanceAdvicePercentage")}
                          type="number"
                          onWheel={event => (document.activeElement as HTMLElement).blur()}
                          id="savingpercentage"
                          {...register(`savings.${index}.percentage` as const, {
                            required: "savings percentage required",
                            validate: {
                              sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                              }
                            },
                            pattern: {
                              value: /^\d+\.?\d{0,2}$/,
                              message: 'Please Enter valid decimal value.'
                            },
                            maxLength: {
                              value: 5,
                              message: "You have exceeded the character limit."
                            },
                            max: {
                              value: 100,
                              message: "Please enter valid savings percentage"
                            },
                            min: {
                              value: 0,
                              message: "Please enter valid savings percentage"
                            }
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">%</InputAdornment>
                            ),
                          }}
                          error={!!errors?.savings?.[index]?.percentage}
                          helperText={errors?.savings?.[index]?.percentage?.message}
                        />
                        {
                          (index > 0) &&
                          <IconButton aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => _deleteSavings(index, fcdetails?.savings[index]?.id)}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                  })
                }
                {fields2?.length < 8 && <Grid item xs={6} sx={{ marginTop: '15px', textAlign: 'left', padding: '12px 16px' }}>
                  <Button variant="outlined" onClick={() => append2({ 'id': '', "savingOptionId": undefined, "percentage": undefined })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                    {t("FourCsAddInvestmentSavings")}
                  </Button>
                </Grid>}

              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 3*/}
          {/* accordion 4 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: incomeContinuationError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ padding: '0 16px' }}>{t("FourCsContinuationIncome")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container>
                  <Grid item xs={12} sx={{ textAlign: 'left', padding: '5px 16px 15px 16px' }}>
                    <InputLabel id="continuation-label">{t("FourCsIncome12")}</InputLabel>
                    <FormControl fullWidth >
                      <Controller
                        {...register(`incomeContinuation.0.value`, {
                          required: 'Please select an option'
                        })}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (

                          <RadioGroup
                            {...field}
                            row
                            {...register(`incomeContinuation.0.value`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}  {...register(`incomeContinuation.0.value`)} value="LOW" control={<CheckRadioButton />} label="Not at all" />
                            <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }}  {...register(`incomeContinuation.0.value`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="Slightly" />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }}  {...register(`incomeContinuation.0.value`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="Moderately" />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.0.value`)} value="HIGH" control={<CheckRadioButton />} label="Severely" />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.incomeContinuation?.[0]?.value?.message?.toString()}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'left', padding: '15px 16px' }}>
                    <InputLabel id="continuation-label">{t("FourCsIncome24")}</InputLabel>
                    <Controller
                      {...register(`incomeContinuation.1.value`, {
                        required: 'Please select an option'
                      })}
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <RadioGroup
                            {...field}
                            row
                            {...register(`incomeContinuation.1.value`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.1.value`)} value="LOW" control={<CheckRadioButton />} label="Not at all" />
                            <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.1.value`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="Slightly" />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.1.value`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="Moderately" />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.1.value`)} value="HIGH" control={<CheckRadioButton />} label="Severely" />
                          </RadioGroup>
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.incomeContinuation?.[1]?.value?.message?.toString()}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'left', padding: '15px 16px' }}>
                    <InputLabel id="continuation-label">{t("FourCsIncomePermanently")}</InputLabel>
                    <Controller
                      {...register(`incomeContinuation.2.value`, {
                        required: 'Please select an option'
                      })}
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <RadioGroup
                            {...field}
                            row
                            {...register(`incomeContinuation.2.value`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.2.value`)} value="LOW" control={<CheckRadioButton />} label="Not at all" />
                            <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }} {...register(`incomeContinuation.2.value`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="Slightly" />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }}   {...register(`incomeContinuation.2.value`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="Moderately" />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}  {...register(`incomeContinuation.2.value`)} value="HIGH" control={<CheckRadioButton />} label="Severely" />
                          </RadioGroup>
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.incomeContinuation?.[2]?.value?.message?.toString()}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 4*/}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" startIcon={<ArrowBackIcon />} onClick={handleSubmit((data) => {
              _onSubmit(data, 'prev');
            }, (errors) => {
              _onError();
            })} variant="contained" sx={{ background: '#005EB8', float: 'left', width: '10%' }}>
              {t("FinanceAdviceBack")}
            </Button>
            <Button type="submit" disabled={isClosedSession === true || isQuoteGenerated} endIcon={<ArrowForwardIcon />} onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })} variant="contained" sx={{ background: '#005EB8', float: 'right', width: '10%' }}>
              {t("FinanceAdviceNext")}
            </Button>
            <Button disabled={isClosedSession === true} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'dashboard');
            }, (errors) => {
              _onError();
            })} ref={submitRef} sx={{ display: 'none' }}>
              {t("FinanceAdviceNext")}
            </Button>
          </Grid>
        </form>
        {/* end Form */}
      </Grid >
      <DevTool control={control} />
      <ToastContainer />
    </Box >
  );
};

export default FourCs;
