export const sanitizeInputValue = (input:any) =>{
    let parsedInput = input;
    if(typeof input === 'number'){
     parsedInput = input?.toString()
     return +parsedInput?.replace(/<\/?[^>]+(>|$)/g,"")
    }
    return parsedInput?.replace(/<\/?[^>]+(>|$)/g,"")
}

export const validateInput = (input:any)=>{
    let parsedInput = input?.toString()
    const regex = /^[a-zA-Z0-9_.+ ]*$/;
    return regex.test(parsedInput) && parsedInput?.length < 100;
}