const CLIENT_ID = process.env.REACT_APP_OKTA_TYPE === 'GROUP' ? process.env.REACT_APP_GROUP_CLIENT_ID : process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_TYPE === 'GROUP' ? `${process.env.REACT_APP_GROUP_OKTA_ISSUERE}/oauth2/${process.env.REACT_APP_GROUP_AUTHORIZATION_SERVER}`:`${process.env.REACT_APP_OKTA_ISSUERE}/oauth2/${process.env.REACT_APP_AUTHORIZATION_SERVER}`
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/oAuth/callback`;
// eslint-disable-next-line
export default {
  oidc: {
    clientId:CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    features: {
      smsRecovery: true,
      smsVerification: true,
      multiOptionalFactorEnroll: true
    }
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
