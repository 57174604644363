import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux';
import { DevTool } from "@hookform/devtools";
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slider from '@mui/material/Slider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DOMPurify from 'dompurify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { ageCalculator } from '../../components/CommonComponent/AgeCalculator'
import {validateInput,sanitizeInputValue} from '../CommonComponent/ValidateInput'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Accordion,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LoopIcon from "@mui/icons-material/Loop";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import summaryImg from "../../Images/Frame.png"
import dayjs, { Dayjs } from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type FormValues = {
  "sessionId": string,
  "currency": string,
  "dts": {
    'id': string,
    "familyHomeCountry": string,
    "familyOwnProperty": string,
    "familyProvider": number,
    "familyUponDeath": string,
    "inCaseOfDemise": number | undefined,
    "isHouseMortgage": string,
    "leaveMoneyBehind": string,
    "preferredDeathPayment": string,
    "protectYourSelf": string,
    "futureExpenses": {
      "id": string,
      "type": string,
      "description": string,
      "priority": number | undefined,
      "targetYear": any | undefined,
      "countryCode": string | null,
      "aspiration": string,
      "amount": number | undefined,
      "duration": number | undefined
      "dependentId": string
    }[]
  },
  "ci": {
    'id': string,
    "ciProtection": string,
    "criticalIllnessDurationMonths": number | undefined,
    "criticalIllnessLumpSum": number | undefined,
    "criticalIllnessMonthlyAmount": number | undefined,
    "disabilityAnnualAmount": number | undefined
  },
  "responsibility": {
    'id': string,
    "expectedRegularExpense": number | undefined,
    "familyLifeStyle": string,
    "interestRate": number | undefined,
    "inflationRate": number | undefined,
  },
  "livingTooLong": {
    'id': string,
    "annualExpensesInYourRetirement": number | undefined,
    "aspiration": string,
    "countryCode": string,
    "familyLifeStyle": string,
    "hasGoalAssets": boolean,
    "importantHaveEnoughMoney": number | undefined,
    "inflationRate": number | undefined,
    "interestRate": number | undefined,
    "investmentPercentage": number | undefined,
    "retirementAge": number | undefined,
    "retirementDuration": number | undefined,
    "targetAmount": number | undefined,
    "targetYear": number | undefined
  }
}
function FourRisksComponent({formUpdatedStatus,setFormUpdatedStatus,setIsFormSubmited, isClosedSession, isQuoteGenerated, _setPageID, _navigateToDashboard, submitRef }: any) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const [disableUpdate, setDisableUpdate] = React.useState<boolean>(false)
  const [currencyError, setCurrencyError] = React.useState<boolean>(false)
  const [dtsError, setDtsError] = React.useState<boolean>(false)
  const [reponsibilityError, setResponsibilityError] = React.useState<boolean>(false)
  const [ciError, setCiError] = React.useState<boolean>(false)
  const [livingTooLongError, setLivingTooLongError] = React.useState<boolean>(false)
  const bearerToken = localStorage.getItem('bearerToken')
  const [open, setOpen] = React.useState(false);
  function calculateAge(dob: string): number {
    const today: Date = new Date();
    const birthDate: Date = new Date(dob);
    let age: number = today.getFullYear() - birthDate.getFullYear();
    const monthDiff: number = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleClose = () => {
    setOpen(false)

    _setPageID(3)

  }
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const marks = [
    {
      value: 10,
      label: '',
    },
    {
      value: 20,
      label: '',
    },
    {
      value: 30,
      label: '',
    },
    {
      value: 40,
      label: '',
    },
    {
      value: 50,
      label: '',
    },
  ];
  const _getFlag = (value: any) => {
    if (value === undefined || value === '') {
      return false
    } else {
      return true
    }
  }
  const _getYear = (value: any) => {
    let year = 0
    if (value !== '' || value !== undefined) {
      year = new Date(value).getFullYear();
    }
    return year;
  }
  const _getPriority = (newValue: any) => {
    if (newValue === 10) {
      return 'LOW'
    }
    else if (newValue === 20) {
      return 'LOW_MEDIUM'
    }
    else if (newValue === 30) {
      return 'MEDIUM'
    }
    else if (newValue === 40) {
      return 'MEDIUM_HIGH'
    }
    else if (newValue === 50) {
      return 'HIGH'
    }
    else {
      return 'LOW'
    }

  }
  const getMinTargetDate = (dependentId: string, type: string) => {
    if (type?.includes('MARRIAGE')) {
      let minTargetYear = new Date().getFullYear();
      return formatedDate("01/01/" + minTargetYear.toString());
    } else {
      if (dependentId) {
        let DOB = getDependents.filter((x: any) => x.id == dependentId)[0]?.dob;
        let targetYear: any = ageCalculator(new Date(DOB).getFullYear());
        let minTargetYear = (new Date(DOB).getFullYear()) + 18;
        let minYear = new Date().getFullYear() + 5
        if (minTargetYear >= minYear) {
          return formatedDate("01/01/" + (minTargetYear - 2).toString());
        } else {
          return formatedDate("01/01/" + minYear.toString());
        }
      } else {
        let minTargetYear = new Date().getFullYear() + 5;
        return formatedDate("01/01/" + minTargetYear.toString());
      }
    }
  }
  const getMaxTargetDate = (dependentId: string, type: string) => {
    if (type?.includes('MARRIAGE')) {
      return formatedDate("12/31/2090");
    } else {
      if (dependentId) {
        let DOB = getDependents.filter((x: any) => x.id == dependentId)[0]?.dob;
        let targetYear: any = ageCalculator(new Date(DOB).getFullYear());
        let minTargetYear = (new Date(DOB).getFullYear()) + 18;
        let minYear = new Date().getFullYear() + 5
        if (minTargetYear < minYear) {
          return formatedDate("12/31/" + (minYear + 15).toString());
        } else if (minTargetYear) {
          return formatedDate("12/31/" + (minTargetYear + 2).toString());
        } else {
          return formatedDate("12/12/2090");
        }

      } else {
        return formatedDate("12/12/2090");
      }
    }
  }
  const _getSliderNumber = (newValue: any) => {
    if (newValue === 'LOW') {
      return 10
    }
    else if (newValue === 'LOW_MEDIUM') {
      return 20
    }
    else if (newValue === 'MEDIUM') {
      return 30
    }
    else if (newValue === 'MEDIUM_HIGH') {
      return 40
    }
    else if (newValue === 'HIGH') {
      return 50
    }
    else {
      return 10
    }

  }
  const addMinValueByOne = (opr: string) => {
    let value = Number(fourRisk?.livingTooLong?.retirementAge) || 0;
    const increment = opr === 'plus' ? 1 : -1;
    value += increment;
    setValue(`livingTooLong.retirementAge`, value);
  }

  const addMinExpenseCoveredByOne = (opr: string) => {
    let value = Number(fourRisk?.ci?.criticalIllnessDurationMonths) || 0;
    const increment = opr === 'plus' ? 1 : -1;
    value += increment;
    setValue(`ci.criticalIllnessDurationMonths`, value);
  }

  const rateVisecountryList = useSelector((state: any) => state.genericDetails.rateVisecountryList);
  const countryDialCodeList = useSelector((state: any) => state.genericDetails.countryCodeList);
  const countryRate = useSelector((state: any) => state.genericDetails.countryRate);
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [details, setDetails] = React.useState<any>()
  const [fourRiskSummaryDetails, setSummaryDetails] = React.useState<any>()
  const [formDetails, setFormDetails] = React.useState<any>()
  const [dayingTooSoonRates, setDayingTooSoonRates] = React.useState<any>()
  const [livingLongRates, setLivingLongRates] = React.useState<any>()
  const [disabledDts, setDisabledDts] = React.useState<boolean>(false)
  const [disabledCi, setDisabledCi] = React.useState<boolean>(false)
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [selectedCurrency, setSelectedCurrency] = React.useState<string>('')
  const [currentDependentId, setCurrentDependentId] = React.useState<string>('')
  const [getDependents, setDependents] = React.useState<any>([])
  const [userAge, setUserAge] = React.useState<any>([])

  /* daying too soon expense calculation */

  const getDtsExpense = async (detailsObj: {}) => {
    const response = await axios.post(baseUrl + '/calculate/regular-expense', detailsObj, requestOptions)
      .then((response) => {
        let value = response?.data?.toFixed(2)
        setValue('responsibility.expectedRegularExpense', +DOMPurify.sanitize(value))
      }).catch(error => {
        if (error.response.status === 401) {
          navigate('/login');
        } else {
          toast.error('Something went wrong, please try again later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }

  /* end daying too soon expense calculation */

  /* living too long expense calculation */

  const getLivingLongExpense = async (detailsObj: {}) => {
    const response = await axios.post(baseUrl + '/calculate/regular-expense', detailsObj, requestOptions)
      .then((response) => {
        let value = response?.data?.toFixed(2)
        setValue('livingTooLong.annualExpensesInYourRetirement', +DOMPurify.sanitize(value))
      }).catch(error => {
        if (error.response.status === 401) {
          navigate('/login');
        } else {
          toast.error('Something went wrong, please try again later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }

  /* end daying too soon expense calculation */

  const _getRates = (country: string) => {
    return countryRate.filter((element: any) => element.countryCode === country)
  }

  const formatedDate = (value: any) => {
    if (!value || value.$d === 'Invalid Date') {
      return null;
    }
    if (value.$d !== 'Invalid Date' && value.$d !== undefined) {
      const date = new Date(value.$d);
      return dayjs(date);
    }
    const date = new Date(isNaN(value) ? value : `10-02-${value}`);
    return dayjs(date);
  }
  const _getFutureExpense = (detailsArray: []) => {
    return detailsArray?.filter((element: any) => element.isFo === false)
  }
  const [maxTargetYearDate, setMaxTargetYearDate] = React.useState<any>(formatedDate('12/31/2072'));
  const [minTargetYearDate, setMinTargetYearDate] = React.useState<any>(formatedDate('01/01/2028'));

  /* country wise rates logic */
  React.useEffect(() => {
    let dtscountry = (details?.fourR?.dts?.familyHomeCountry === '' || details?.fourR?.dts?.familyHomeCountry === undefined) ? 'AE' : details?.fourR?.dts?.familyHomeCountry;
    let livingLongCountry = (details?.fourR?.livingTooLong?.countryCode === '' || details?.fourR?.livingTooLong?.countryCode === undefined) ? 'AE' : details?.fourR?.livingTooLong?.countryCode
    let dtsRate = _getRates(dtscountry)
    let livingLongRates = _getRates(livingLongCountry)
    if (dtsRate?.length > 0) {
      setDayingTooSoonRates(dtsRate[0])
    }
    if (livingLongRates?.length > 0) {
      setLivingLongRates(livingLongRates[0])
    }

  }, [details])

  React.useEffect(() => {
    _getSummary(false)
  }, [])

  const _getSummary = async (shouldResetForm: boolean) => {
    const response = await fetch(
      baseUrl + '/calculate/4r-summary?id=' + sessionStorage.getItem('sessiondetail'),
      requestOptions
    );
    let data = await response.json()
    if (data) {
      fetchData(shouldResetForm)
    }
    setShowSpinner(false)
    setSummaryDetails(data?.calculatedResult)

  }
  const _getRadioButtonValue = (flag: boolean) => {
    if (flag && flag === true) {
      return 'yes'
    } else if (flag === false || flag === 'false') {
      return 'no'
    } else {
      return ''
    }
  }
  const _getValue = (newValue: any) => {
    let val = 10;
    if (newValue === 'LOW') {
      val = 10
    }
    else if (newValue === 'LOW_MEDIUM') {
      val = 20
    }
    else if (newValue === 'MEDIUM') {
      val = 30
    }
    else if (newValue === 'MEDIUM_HIGH') {
      val = 40
    }
    else if (newValue === 'HIGH') {
      val = 50
    }
    else {
      val = newValue
    }
    return Number(val)
  }
  const getValueFutureExpense = (index: number) => {
    let value = fourRisk?.dts.futureExpenses[index]?.priority
    let num = _getValue(value)
    setValue(`dts.futureExpenses.${index}.priority`, num)
  }
  const _getLabels = (value: any) => {
    switch (value) {
      case 10:
        return 'Not important to me'
      case 'LOW':
        return 'Not important to me'
      case 20:
        return 'Somewhat important'
      case 'LOW_MEDIUM':
        return 'Somewhat important'
      case 30:
        return 'Important'
      case 'MEDIUM':
        return 'Important'
      case 40:
        return 'Very important'
      case 'MEDIUM_HIGH':
        return 'Very important'
      case 50:
        return 'I must achieve this'
      case 'HIGH':
        return 'I must achieve this'
      default:
        return 'Not important to me'
    }
  }
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const responseDetails = await response.json();
      const data = responseDetails?.fourR
      setSelectedCurrency(data?.currency ? data?.currency : 'USD')
      setShowSpinner(false)
      setDetails(responseDetails);
      const futureExpenseArrayDetails = data?.dts?.futureExpenses?.map((element: any) => {
        let obj = { ...element }
        if (element?.dependentId) {
          obj.type = element?.type + "|" + element?.dependentId;
        }
        
        return obj;
      });
      let dtscountry = responseDetails?.fourR?.dts?.familyHomeCountry ? responseDetails?.fourR?.dts?.familyHomeCountry : 'AE';
      let livingLongCountry = responseDetails?.fourR?.livingTooLong?.countryCode ? responseDetails?.fourR?.livingTooLong?.countryCode : 'AE';
      let dtsRate = _getRates(dtscountry)
      let livingLongRates = _getRates(livingLongCountry)
      let dtsRateObj;
      let livingLongObj;
      if (dtsRate?.length > 0) {
        dtsRateObj = dtsRate[0]
      }
      if (livingLongRates?.length > 0) {
        livingLongObj = livingLongRates[0]
      }

      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        "currency": data?.currency ? data?.currency : 'USD',
        "dts": {
          'id': data?.dts?.id || '',
          "familyHomeCountry": data?.dts?.familyHomeCountry || 'AE',
          "familyOwnProperty": (data?.dts?.familyOwnProperty !== '') ? _getRadioButtonValue(data?.dts?.familyOwnProperty) : '',
          "familyProvider": data?.dts?.familyProvider || 0,
          "familyUponDeath": data?.dts?.familyUponDeath || '',
          "inCaseOfDemise": data?.dts?.inCaseOfDemise ? _getSliderNumber(data?.dts?.inCaseOfDemise) : 0,
          "isHouseMortgage": (data?.dts?.isHouseMortgage !== '') ? _getRadioButtonValue(data?.dts?.isHouseMortgage) : '',
          "leaveMoneyBehind": (data?.dts?.leaveMoneyBehind !== '') ? _getRadioButtonValue(data?.dts?.leaveMoneyBehind) : '',
          "preferredDeathPayment": data?.dts?.preferredDeathPayment || '',
          "protectYourSelf": (data?.dts?.protectYourSelf !== '') ? _getRadioButtonValue(data?.dts?.protectYourSelf) : '',
          "futureExpenses": futureExpenseArrayDetails?.length > 0 ? futureExpenseArrayDetails : [],
        },
        "ci": {
          'id': data?.ci?.id || '',
          "ciProtection": (data?.ci?.ciProtection !== '') ? _getRadioButtonValue(data?.ci?.ciProtection) : '',
          "criticalIllnessDurationMonths": data?.ci?.criticalIllnessDurationMonths,
          "criticalIllnessLumpSum": data?.ci?.criticalIllnessLumpSum || undefined,
          "criticalIllnessMonthlyAmount": data?.ci?.criticalIllnessMonthlyAmount || 0,
          "disabilityAnnualAmount": data?.ci?.disabilityAnnualAmount || 0,
        },
        "responsibility": {
          'id': data?.responsibility?.id || '',
          "expectedRegularExpense": data?.responsibility?.expectedRegularExpense || undefined,
          "familyLifeStyle": data?.responsibility?.familyLifeStyle || '',
          "interestRate": data?.responsibility?.interestRate ? data?.responsibility?.interestRate! : dtsRateObj?.interestRate,
          "inflationRate": data?.responsibility?.inflationRate ? data?.responsibility?.inflationRate! : dtsRateObj?.inflationRate,
        },
        "livingTooLong": {
          'id': data?.livingTooLong?.id || '',
          "annualExpensesInYourRetirement": data?.livingTooLong?.annualExpensesInYourRetirement || '',
          "aspiration": data?.livingTooLong?.aspiration || "LOW",
          "countryCode": data?.livingTooLong?.countryCode || "AE",
          "familyLifeStyle": data?.livingTooLong?.familyLifeStyle || "",
          "hasGoalAssets": false,
          "importantHaveEnoughMoney": data?.livingTooLong?.importantHaveEnoughMoney ? _getSliderNumber(data?.livingTooLong?.importantHaveEnoughMoney) : 0,
          "inflationRate": data?.livingTooLong?.inflationRate! || livingLongObj?.inflationRate,
          "interestRate": data?.livingTooLong?.interestRate ? data?.livingTooLong?.interestRate! : livingLongObj?.interestRate,
          "investmentPercentage": 0,
          "retirementAge": data?.livingTooLong?.retirementAge || undefined,
          "retirementDuration": data?.livingTooLong?.retirementDuration || 0,
          "targetAmount": data?.livingTooLong?.targetAmount || 0,
          "targetYear": data?.livingTooLong?.targetYear || 0,
        }
      }
    },
    mode: 'all'
  });
  const { register, control, handleSubmit, clearErrors, reset, formState, formState: { errors, touchedFields, isDirty }, watch, setValue, getValues } = form
  const { fields, append, remove } = useFieldArray({
    name: 'dts.futureExpenses',
    control
  })
  const fourRisk = watch()
  const _getAlreadyAddedTypeFlag = (value: string) => {
    const expenseArray = fourRisk?.dts.futureExpenses;
    const financialObjectivesArray = details?.financialObjectives?.financialObjectives?.map((element: any) => {
      let obj = { ...element }
      if (element?.dependentId) {
        obj.type = element?.type + "|" + element?.dependentId;
      }
      return obj;
    });;
    const combineArray = [...expenseArray, ...financialObjectivesArray]
    let arrayValue = combineArray?.filter(element => element?.type?.includes(value))
    return arrayValue?.length > 0
  }

  React.useEffect(() => {
    let status = details?.progress?.fourR?.status
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {
    const { dts, responsibility, ci, livingTooLong } = errors

    if ((dts && (dts.hasOwnProperty('familyProvider') || dts.hasOwnProperty('familyHomeCountry') || dts.hasOwnProperty('isHouseMortgage') || dts.hasOwnProperty('familyOwnProperty') ||
      dts.hasOwnProperty('futureExpenses') || dts.hasOwnProperty('leaveMoneyBehind') || dts.hasOwnProperty('preferredDeathPayment') || dts.hasOwnProperty('familyUponDeath'))) ||
      (responsibility && (responsibility.hasOwnProperty('interestRate') ||
        responsibility.hasOwnProperty('familyLifeStyle') || responsibility.hasOwnProperty('expectedRegularExpense') || responsibility.hasOwnProperty('inflationRate')))) {
      setDtsError(true)
    }
    else {
      setDtsError(false)
    };

    // `if ((dts && (dts.hasOwnProperty('futureExpenses') || dts.hasOwnProperty('leaveMoneyBehind') || dts.hasOwnProperty('preferredDeathPayment'))) ||

    //   (responsibility && (responsibility.hasOwnProperty('familyLifeStyle') || responsibility.hasOwnProperty('expectedRegularExpense')))) { setResponsibilityError(true) }
    // else { setResponsibilityError(false); }`
    if (ci && (ci.hasOwnProperty('criticalIllnessLumpSum') || ci.hasOwnProperty('criticalIllnessDurationMonths') || ci.hasOwnProperty('criticalIllnessMonthlyAmount') || ci.hasOwnProperty('ciProtection'))) { setCiError(true) } else { setCiError(false) }
    if (livingTooLong && (livingTooLong.hasOwnProperty('annualExpensesInYourRetirement') ||
      livingTooLong.hasOwnProperty('countryCode') ||
      livingTooLong.hasOwnProperty('inflationRate') ||
      livingTooLong.hasOwnProperty('interestRate') ||
      livingTooLong.hasOwnProperty('familyLifeStyle') || livingTooLong.hasOwnProperty('retirementAge'))) { setLivingTooLongError(true) } else { setLivingTooLongError(false) }
  }
  const fetchData = async (shouldReset = true) => {
    setShowSpinner(true)
    try {
      const response = await axios.get(baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions);
      const responseDetails = await response.data;
      setDependents(responseDetails?.personalDetails?.dependents)

      setUserAge(calculateAge(responseDetails?.personalDetails?.dob))

      setDetails(responseDetails)
      const data = responseDetails?.fourR
      setShowSpinner(false)
      let dtscountry = responseDetails?.fourR?.dts?.familyHomeCountry ? responseDetails?.fourR?.dts?.familyHomeCountry : 'AE';
      let livingLongCountry = responseDetails?.fourR?.livingTooLong?.countryCode ? responseDetails?.fourR?.livingTooLong?.countryCode : 'AE';
      let dtsRate = _getRates(dtscountry)
      let livingLongRates = _getRates(livingLongCountry)
      let dtsRateObj;
      let livingLongObj;
      if (dtsRate?.length > 0) {
        dtsRateObj = dtsRate[0]
      }
      if (livingLongRates?.length > 0) {
        livingLongObj = livingLongRates[0]
      }

      const futureExpenseArrayDetails = data?.dts?.futureExpenses?.map((element: any) => {
        let obj = { ...element }
        if (element?.dependentId) {
          obj.type = element?.type + "|" + element?.dependentId;
        }
        
        return obj;
      });

      let obj = {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        "currency": data?.currency ? data?.currency : 'USD',
        "dts": {
          'id': data?.dts?.id || '',
          "familyHomeCountry": data?.dts?.familyHomeCountry || 'AE',
          "familyOwnProperty": (data?.dts?.familyOwnProperty !== '') ? _getRadioButtonValue(data?.dts?.familyOwnProperty) : '',
          "familyProvider": data?.dts?.familyProvider || 0,
          "familyUponDeath": data?.dts?.familyUponDeath || '',
          "inCaseOfDemise": data?.dts?.inCaseOfDemise ? _getSliderNumber(data?.dts?.inCaseOfDemise) : 0,
          "isHouseMortgage": (data?.dts?.isHouseMortgage !== '') ? _getRadioButtonValue(data?.dts?.isHouseMortgage) : '',
          "leaveMoneyBehind": (data?.dts?.leaveMoneyBehind !== '') ? _getRadioButtonValue(data?.dts?.leaveMoneyBehind) : '',
          "preferredDeathPayment": data?.dts?.preferredDeathPayment || '',
          "protectYourSelf": (data?.dts?.protectYourSelf !== '') ? _getRadioButtonValue(data?.dts?.protectYourSelf) : '',
          "futureExpenses": data?.dts?.futureExpenses?.length > 0 ? futureExpenseArrayDetails : [],
        },
        "ci": {
          'id': data?.ci?.id || '',
          "ciProtection": (data?.ci?.ciProtection !== '') ? _getRadioButtonValue(data?.ci?.ciProtection) : '',
          "criticalIllnessDurationMonths": data?.ci?.criticalIllnessDurationMonths || undefined,
          "criticalIllnessLumpSum": data?.ci?.criticalIllnessLumpSum || undefined,
          "criticalIllnessMonthlyAmount": data?.ci?.criticalIllnessMonthlyAmount || 0,
          "disabilityAnnualAmount": data?.ci?.disabilityAnnualAmount || 0,
        },
        "responsibility": {
          'id': data?.responsibility?.id || '',
          "expectedRegularExpense": data?.responsibility?.expectedRegularExpense || undefined,
          "familyLifeStyle": data?.responsibility?.familyLifeStyle || '',
          "interestRate": data?.responsibility?.interestRate ? data?.responsibility?.interestRate! : dtsRateObj?.interestRate,
          "inflationRate": data?.responsibility?.inflationRate ? data?.responsibility?.inflationRate! : dtsRateObj?.inflationRate,
        },
        "livingTooLong": {
          'id': data?.livingTooLong?.id || '',
          "annualExpensesInYourRetirement": data?.livingTooLong?.annualExpensesInYourRetirement || '',
          "aspiration": data?.livingTooLong?.aspiration || "LOW",
          "countryCode": data?.livingTooLong?.countryCode || "AE",
          "familyLifeStyle": data?.livingTooLong?.familyLifeStyle || "",
          "hasGoalAssets": false,
          "importantHaveEnoughMoney": data?.livingTooLong?.importantHaveEnoughMoney ? _getSliderNumber(data?.livingTooLong?.importantHaveEnoughMoney) : 0,
          "inflationRate": data?.livingTooLong?.inflationRate! || livingLongObj?.inflationRate,
          "interestRate": data?.livingTooLong?.interestRate ? data?.livingTooLong?.interestRate! : livingLongObj?.interestRate,
          "investmentPercentage": 0,
          "retirementAge": data?.livingTooLong?.retirementAge || undefined,
          "retirementDuration": data?.livingTooLong?.retirementDuration || 0,
          "targetAmount": data?.livingTooLong?.targetAmount || 0,
          "targetYear": data?.livingTooLong?.targetYear || 0,
        }
      }
      if (data) {
        setDisableUpdate(false)
      }
      if (shouldReset) {
        reset(obj);
      }

    } catch (error: any) {
      if (error.response.status === 401) {
        navigate('/login');
      } else {
        toast.error('Something went wrong, please try again later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    }
  };

  React.useEffect(() => {
    if (fourRisk?.dts?.protectYourSelf === 'no') {
      setDisabledDts(true)
      setValue('dts.familyOwnProperty', 'no')
      setValue('dts.familyUponDeath', 'REMAIN_IN_COUNTRY')
      setValue('dts.familyProvider', 1)
      setValue('dts.isHouseMortgage', 'no')
      setValue('dts.preferredDeathPayment', 'LUMPSUM_PAYOUT')
    } else {
      setDisabledDts(false)
    }
  }, [fourRisk?.dts?.protectYourSelf])

  const changeCurrency = (value: any) => {
    if (value) {
      setSelectedCurrency(value)
      fourRisk?.dts?.futureExpenses?.map((element, index) => {
        getHowMuchNeed(index)
      });
    }
  }


  React.useEffect(() => {
    if (fourRisk?.ci?.ciProtection === 'no') {
      setDisabledCi(true)
      setValue('ci.criticalIllnessDurationMonths', 0)
      setValue('ci.criticalIllnessLumpSum', 0)
      setValue('ci.criticalIllnessMonthlyAmount', 0)
    } else {
      setDisabledCi(false)
    }
  }, [fourRisk?.ci?.ciProtection])
  React.useEffect(() => {
    if (touchedFields?.currency || touchedFields?.responsibility?.familyLifeStyle || touchedFields?.dts?.familyOwnProperty) {
      if (fourRisk?.currency && fourRisk?.responsibility?.familyLifeStyle && fourRisk?.dts?.familyOwnProperty) {
        getDtsExpense({
          "country": fourRisk?.dts?.familyHomeCountry ? fourRisk?.dts?.familyHomeCountry : 'AE',
          "expenseType": "dying_too_soon",
          "lifeStyle": fourRisk?.responsibility?.familyLifeStyle,
          "familyOwnProperty": fourRisk?.dts?.familyOwnProperty,
          "frequency": fourRisk?.dts?.preferredDeathPayment === 'LUMPSUM_PAYOUT' ? 'yearly' : 'monthly',
          "outputCurrency": fourRisk?.currency || "USD",
          "dependents": ""
        })
      }
    }
  }, [touchedFields?.currency, touchedFields?.responsibility?.familyLifeStyle, touchedFields?.dts?.familyOwnProperty, fourRisk?.currency, fourRisk?.dts?.preferredDeathPayment, fourRisk?.dts?.familyHomeCountry, fourRisk?.responsibility?.familyLifeStyle, fourRisk?.dts?.familyOwnProperty])

  React.useEffect(() => {
    if (touchedFields?.currency || touchedFields?.livingTooLong?.familyLifeStyle) {
      if (fourRisk?.currency && fourRisk?.livingTooLong?.familyLifeStyle && fourRisk?.livingTooLong?.countryCode) {
        getLivingLongExpense({
          "country": fourRisk?.livingTooLong?.countryCode || "AE",
          "expenseType": "living_too_long",
          "lifeStyle": fourRisk?.livingTooLong?.familyLifeStyle,
          "familyOwnProperty": true,
          "frequency": "yearly",
          "outputCurrency": fourRisk?.currency || "USD",
          "dependents": ""
        })
      }
    }
  }, [touchedFields?.currency, touchedFields?.livingTooLong?.familyLifeStyle, fourRisk?.currency, fourRisk?.livingTooLong?.familyLifeStyle, fourRisk?.livingTooLong?.countryCode])

  const _setDtRates = () => {
    clearErrors('responsibility.inflationRate')
    clearErrors('responsibility.interestRate')
    let dtscountry = (fourRisk?.dts?.familyUponDeath === 'REMAIN_IN_COUNTRY' || fourRisk?.dts?.familyHomeCountry === '' || fourRisk?.dts?.familyHomeCountry === undefined) ? 'AE' : fourRisk?.dts?.familyHomeCountry;
    let dtsRate = _getRates(dtscountry)
    if (dtsRate?.length > 0) {
      setDayingTooSoonRates(dtsRate[0])
      setValue('responsibility.inflationRate', dtsRate[0]?.inflationRate)
      setValue('responsibility.interestRate', dtsRate[0]?.interestRate)
    }
  }
  const _getLTRates = () => {
    let ltcountry = (fourRisk?.livingTooLong?.countryCode === '' || fourRisk?.livingTooLong?.countryCode === undefined) ? 'AE' : fourRisk?.livingTooLong?.countryCode;
    let livingLongRatesArray = _getRates(ltcountry)
    if (livingLongRatesArray?.length > 0) {
      clearErrors('livingTooLong.inflationRate')
      clearErrors('livingTooLong.interestRate')
      setLivingLongRates(livingLongRatesArray[0])
      setValue('livingTooLong.inflationRate', livingLongRatesArray[0]?.inflationRate)
      setValue('livingTooLong.interestRate', livingLongRatesArray[0]?.interestRate)
    }
  }
  const _onSubmit = (data: FormValues, action: string) => {
    //setFormUpdatedStatus({...formUpdatedStatus,fourRisk:(details?.status !== 'MEETING_1' && isDirty)?'updated':'notupdated'})
    if (action === 'summary') {
      setShowSpinner(true)
      setDisableUpdate(true)
    }

    let reponse = { ...data }
    let expenses = reponse?.dts?.futureExpenses?.map((element: any) => {
      let detailsObject = { ...element }
      detailsObject.id === '' && delete detailsObject.id
      delete detailsObject.customerId
      if (element?.type?.includes("|")) {
        let valueType = element?.type?.split("|")
        if (valueType?.length > 0) {
          detailsObject.type = valueType[0]
        }
      } else {
        detailsObject.dependentId = ''
      }
      detailsObject.duration = element?.duration ? Number(element?.duration) : element?.duration;
      detailsObject.targetYear = (typeof element?.targetYear === 'number') ? element?.targetYear : _getYear(element?.targetYear)
      detailsObject.priority = (typeof element?.priority === 'number') ? _getPriority(element?.priority) : element?.priority
      return detailsObject
    })
    let dts = {
      ...reponse.dts, futureExpenses: expenses,
      familyOwnProperty: (reponse.dts.familyOwnProperty === 'yes' ? true : false),
      leaveMoneyBehind: (reponse.dts.leaveMoneyBehind === 'yes' ? true : false),
      protectYourSelf: (reponse.dts.protectYourSelf === 'yes' ? true : false),
      isHouseMortgage: (reponse.dts.isHouseMortgage === 'yes' ? true : false),
      inCaseOfDemise: (typeof reponse.dts.inCaseOfDemise === 'number') ? _getPriority(reponse.dts.inCaseOfDemise) : reponse.dts.inCaseOfDemise
    }
    let responsibility = { ...reponse.responsibility }
    let livingTooLong = { ...reponse.livingTooLong, importantHaveEnoughMoney: (typeof reponse.livingTooLong.importantHaveEnoughMoney === 'number') ? _getPriority(reponse.livingTooLong.importantHaveEnoughMoney) : reponse.livingTooLong.importantHaveEnoughMoney }
    let ci = { ...reponse.ci, ciProtection: (reponse.ci.ciProtection === 'yes' ? true : false), criticalIllnessDurationMonths: reponse?.ci?.criticalIllnessDurationMonths ? Number(reponse?.ci?.criticalIllnessDurationMonths) : 0, criticalIllnessMonthlyAmount: reponse?.ci?.criticalIllnessMonthlyAmount ? Number(reponse?.ci?.criticalIllnessMonthlyAmount) : 0 }
    let payload = { ...reponse, dts: { ...dts }, responsibility: { ...responsibility }, livingTooLong: { ...livingTooLong }, ci: { ...ci } }

    setFourRiskDetails(payload, action)

  }
  const setFourRiskDetails = async (fourRiskDetails: {}, action: string) => {
    const response = await axios.post(baseUrl + '/customers/four-r', fourRiskDetails, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        })
        //_setPageID(5)
        let dependents = response?.data?.personalDetails?.dependents;
        if (action === 'next') {
          if (dependents?.length > 0) {
            let childCount = dependents?.filter((element: any) => element?.relationId === 4)
            let expense = response?.data?.fourR?.dts?.futureExpenses?.filter((element: any) => {
              return element?.type === 'UNIVERSITY' && element?.dependentId
            }
            )
            if (childCount?.length === expense?.length) {
              _setPageID(3)
            } else {
              setOpen(true)
            }
          } else {
            _setPageID(3)
          }
        } else if (action === 'summary') {

          setIsError('#23AA01')
          setResponsibilityError(false)
          setLivingTooLongError(false)
          setCiError(false)
          setDtsError(false)
          _getSummary(true)
          setExpanded('panel2')

        } else if (action === 'dashboard') {
          _navigateToDashboard(true);
        }
        else {
          _setPageID(1)
        }

      }).catch(error => {
        if (error.response.status === 401) {
          navigate('/login');
        } else {
          setDisableUpdate(false)
          setShowSpinner(false)
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }
  /* submit code end */
  const familyOwnProperty = fourRisk?.dts?.familyOwnProperty;

  const handleIncrementInflationinterestRate = () => {
    let currentRate = Number(fourRisk?.responsibility?.inflationRate)
    if (currentRate) {
      let sumvalue = currentRate ? Number((currentRate + 1).toFixed(2)) : 0;
      if (currentRate >= dayingTooSoonRates?.maxInflationRate && sumvalue > dayingTooSoonRates?.maxInflationRate) {
        setValue('responsibility.inflationRate', Number(Number(dayingTooSoonRates?.maxInflationRate).toFixed(2)))
      } else if (currentRate < dayingTooSoonRates?.maxInflationRate && currentRate < dayingTooSoonRates?.minInflationRate && sumvalue < dayingTooSoonRates?.maxInflationRate) {
        setValue('responsibility.inflationRate', sumvalue)
      } else {
        setValue('responsibility.inflationRate', Number(Number(dayingTooSoonRates?.maxInflationRate).toFixed(2)))
      }
    } else {
      setValue('responsibility.inflationRate', Number(Number(dayingTooSoonRates?.maxInflationRate).toFixed(2)))
    }
  }
  const handleMinusInflationinterestRate = () => {
    let currentRate = Number(fourRisk?.responsibility?.inflationRate)
    if (currentRate) {
      let minvalue = currentRate ? Number((currentRate - 1).toFixed(2)) : 0;
      if (currentRate <= dayingTooSoonRates?.minInflationRate && minvalue <= dayingTooSoonRates?.minInflationRate) {
        setValue('responsibility.inflationRate', Number(Number(dayingTooSoonRates?.minInflationRate).toFixed(2)))
      } else if (currentRate < dayingTooSoonRates?.maxInflationRate && currentRate > dayingTooSoonRates?.minInflationRate && minvalue < dayingTooSoonRates?.maxInflationRate && minvalue > dayingTooSoonRates?.minInflationRate) {
        setValue('responsibility.inflationRate', minvalue)
      } else {
        setValue('responsibility.inflationRate', Number(Number(dayingTooSoonRates?.minInflationRate).toFixed(2)))
      }
    } else {
      setValue('responsibility.inflationRate', Number(Number(dayingTooSoonRates?.minInflationRate).toFixed(2)))
    }
  }


  const handleIncrementDtsinterestRate = () => {
    let currentRate = Number(fourRisk?.responsibility?.interestRate)
    if (currentRate) {
      let sumvalue = currentRate ? Number((currentRate + 1).toFixed(2)) : 0;
      if (currentRate >= dayingTooSoonRates?.maxInterestRate && sumvalue > dayingTooSoonRates?.maxInterestRate) {
        setValue('responsibility.interestRate', Number(Number(dayingTooSoonRates?.maxInterestRate).toFixed(2)))
      } else if (currentRate < dayingTooSoonRates?.maxInterestRate && sumvalue < dayingTooSoonRates?.maxInterestRate) {
        setValue('responsibility.interestRate', sumvalue)
      } else {
        setValue('responsibility.interestRate', Number(Number(dayingTooSoonRates?.maxInterestRate).toFixed(2)))
      }
    } else {
      setValue('responsibility.interestRate', Number(Number(dayingTooSoonRates?.maxInterestRate).toFixed(2)))
    }
  }

  const handleMinusDtsinterestRate = () => {
    let currentRate = Number(fourRisk?.responsibility?.interestRate)
    if (currentRate) {
      let minvalue = currentRate ? Number((currentRate - 1).toFixed(2)) : 0;
      if (currentRate <= dayingTooSoonRates?.minInterestRate && minvalue <= dayingTooSoonRates?.minInterestRate) {
        setValue('responsibility.interestRate', Number(Number(dayingTooSoonRates?.minInterestRate).toFixed(2)))
      } else if (currentRate < dayingTooSoonRates?.maxInterestRate && currentRate > dayingTooSoonRates?.minInterestRate && minvalue > dayingTooSoonRates?.minInterestRate && minvalue < dayingTooSoonRates?.minInterestRate) {
        setValue('responsibility.interestRate', minvalue)
      } else {
        setValue('responsibility.interestRate', Number(Number(dayingTooSoonRates?.minInterestRate).toFixed(2)))
      }
    } else {
      setValue('responsibility.interestRate', Number(Number(dayingTooSoonRates?.maxInterestRate).toFixed(2)))
    }
  }
  /* living too Long Rates calcaluations*/
  const handleLTIncrementInflationinterestRate = () => {
    let currentRate = Number(fourRisk?.livingTooLong?.inflationRate)
    if (currentRate) {
      let sumvalue = currentRate ? Number((currentRate + 1).toFixed(2)) : 0;
      if (currentRate >= livingLongRates?.maxInflationRate && sumvalue > livingLongRates?.maxInflationRate) {
        setValue('livingTooLong.inflationRate', Number(Number(livingLongRates?.maxInflationRate).toFixed(2)))
      } else if (currentRate < livingLongRates?.maxInflationRate && currentRate < livingLongRates?.minInflationRate && sumvalue < livingLongRates?.maxInflationRate) {
        setValue('livingTooLong.inflationRate', sumvalue)
      } else {
        setValue('livingTooLong.inflationRate', Number(Number(livingLongRates?.maxInflationRate).toFixed(2)))
      }
    } else {
      setValue('livingTooLong.inflationRate', Number(Number(livingLongRates?.maxInflationRate).toFixed(2)))
    }
  }
  const handleLTMinusInflationinterestRate = () => {
    let currentRate = Number(fourRisk?.livingTooLong?.inflationRate)
    if (currentRate) {
      let minvalue = currentRate ? Number((currentRate - 1).toFixed(2)) : 0;
      if (currentRate <= livingLongRates?.minInflationRate && minvalue <= livingLongRates?.minInflationRate) {
        setValue('livingTooLong.inflationRate', Number(Number(livingLongRates?.minInflationRate).toFixed(2)))
      } else if (currentRate < livingLongRates?.maxInflationRate && currentRate > livingLongRates?.minInflationRate && minvalue < livingLongRates?.maxInflationRate && minvalue > livingLongRates?.minInflationRate) {
        setValue('livingTooLong.inflationRate', minvalue)
      } else {
        setValue('livingTooLong.inflationRate', Number(Number(livingLongRates?.minInflationRate).toFixed(2)))
      }
    } else {
      setValue('livingTooLong.inflationRate', Number(Number(livingLongRates?.minInflationRate).toFixed(2)))
    }
  }

  const handleIncrementLTinterestRate = () => {
    let currentRate = Number(fourRisk?.livingTooLong?.interestRate)
    if (currentRate) {
      let sumvalue = currentRate ? Number((currentRate + 1).toFixed(2)) : 0;
      if (currentRate >= livingLongRates?.maxInterestRate && sumvalue > livingLongRates?.maxInterestRate) {
        setValue('livingTooLong.interestRate', Number(Number(livingLongRates?.maxInterestRate).toFixed(2)))
      } else if (currentRate < livingLongRates?.maxInterestRate && sumvalue < livingLongRates?.maxInterestRate) {
        setValue('livingTooLong.interestRate', sumvalue)
      } else {
        setValue('livingTooLong.interestRate', Number(Number(livingLongRates?.maxInterestRate).toFixed(2)))
      }
    } else {
      setValue('livingTooLong.interestRate', Number(Number(livingLongRates?.maxInterestRate).toFixed(2)))
    }
  }

  const handleMinusFamilyLTRateOfReturn = () => {
    let currentRate = Number(fourRisk?.livingTooLong?.interestRate)
    if (currentRate) {
      let minvalue = currentRate ? Number((currentRate - 1).toFixed(2)) : 0;
      if (currentRate <= livingLongRates?.minInterestRate && minvalue <= livingLongRates?.minInterestRate) {
        setValue('livingTooLong.interestRate', Number(Number(livingLongRates?.minInterestRate).toFixed(2)))
      } else if (currentRate < livingLongRates?.maxInterestRate && currentRate > livingLongRates?.minInterestRate && minvalue > livingLongRates?.minInterestRate && minvalue < livingLongRates?.minInterestRate) {
        setValue('livingTooLong.interestRate', minvalue)
      } else {
        setValue('livingTooLong.interestRate', Number(Number(livingLongRates?.minInterestRate).toFixed(2)))
      }
    } else {
      setValue('livingTooLong.interestRate', Number(Number(livingLongRates?.maxInterestRate).toFixed(2)))
    }
  }
  const _deleteFutureExpense = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/future-expense?id=' + id, requestOptions)
        .then((response) => { remove(index) })
    } else {
      remove(index)
    }
  }
  /* expense calculation for future expense */
  const getHowMuchNeed = (id: any) => {
    let selectedYear = 0
    let { countryCode, targetYear, type, aspiration } = fourRisk?.dts.futureExpenses[id]
    let currency = fourRisk?.currency;
    let isUniversity = type?.includes('UNIVERSITY');
    if (targetYear && type && isClosedSession !== true && isQuoteGenerated !== true) {
      if (targetYear === '' || targetYear === undefined) { selectedYear = new Date('01/01/2027').getFullYear() }
      else if (typeof targetYear === 'number') {
        selectedYear = targetYear
      }
      else { selectedYear = new Date(targetYear).getFullYear() }
      if (isUniversity && countryCode && currency && selectedYear) {
        let payload = {
          "country": countryCode,
          "currency": currency,
          "year": selectedYear
        }
        let amount = window.fetch(baseUrl + '/calculate/university-expense', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`
          },
          method: "POST",
          body: JSON.stringify(payload)
        }).then((response) => response.json()).then((data) => {
          setValue(`dts.futureExpenses.${id}.amount`, data?.amount);
        })
        ///dispatch(getUniversityExpense(payload))
      } else if (type === 'PROPERTY' && countryCode && aspiration && selectedYear) {
        let payload = {
          "country": countryCode,
          "currency": currency || 'USD',
          "year": selectedYear,
          "priceRange": aspiration
        }
        window.fetch(baseUrl + '/calculate/property-expense', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`
          },
          method: "POST",
          body: JSON.stringify(payload)
        }).then((response) => response.json()).then((data) => {
          setValue(`dts.futureExpenses.${id}.amount`, data?.amount);
        })
        ///dispatch(getUniversityExpense(payload))
      }
    }
  }

  return (
    <>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <h3 style={{ color: "#012169", marginTop: "30px" }}>
            {t("FinanceAdviceFor")} {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}
          </h3>
        </Grid>
        <Grid style={{ marginTop: "40px" }}>
          <Accordion
            className="accordion" sx={{ border: '2px solid', borderColor: currencyError ? '#FF7B6F' : isErrorColor }}
            defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-control="about-you"
              id="about-you"
            >
              <Typography sx={{ padding: "0 16px", color: "#666666" }}>
                {t("FourRisksBeforeWeStart")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", padding: "12px 16px" }}
              >
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                      {t("FourRisksCurrencyInput")}
                    </FormLabel>
                    <Controller
                      control={control}
                      defaultValue={"" as any}
                      {...register(`currency` as const,{
                        validate: {
                          sanatizeInput: (fieldValue) => {
                            return validateInput(fieldValue) || 'Please provide valid input'
                          }
                        }
                      })}
                      render={({ field }) => (<Select
                        sx={{ textAlign: "left" }}
                        variant="standard"
                        id="currency"
                        label="Which currency will your inputs be in?"
                        error={!!errors?.currency}
                        {...field}
                        onChange={(e) => { field.onChange(e?.target?.value); changeCurrency(e?.target?.value) }}
                      >
                        <MenuItem value='AED' selected>AED</MenuItem>
                        <MenuItem value='AUD' selected>AUD</MenuItem>
                        <MenuItem value='BAH' selected>BAH</MenuItem>
                        <MenuItem value='CAD'>CAD</MenuItem>
                        <MenuItem value='CHF'>CHF</MenuItem>
                        <MenuItem value='CNY'>CNY</MenuItem>
                        <MenuItem value='EUR'>EUR</MenuItem>
                        <MenuItem value='GBP'>GBP</MenuItem>
                        <MenuItem value='INR'>INR</MenuItem>
                        <MenuItem value='KRW'>KRW</MenuItem>
                        <MenuItem value='MYR'>MYR</MenuItem>
                        <MenuItem value='QAT'>QAT</MenuItem>
                        <MenuItem value='SGD'>SGD</MenuItem>
                        <MenuItem value='USD'>USD</MenuItem>
                        <MenuItem value='PHP'>PHP</MenuItem>
                      </Select>)} />
                  </FormControl>
                  <FormHelperText sx={{ color: '#666666', fontSize: '600', marginTop: '15px' }}>{fourRisk?.currency !== 'USD' ? 'The Planning currency has been changed from USD to ' + fourRisk?.currency  + ' Please relook at the planning and adjust the values accordingly.' : ''}</FormHelperText>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* daying Too soon section */}
        <Grid style={{ marginTop: "40px", textAlign: "left" }}>
          <Accordion
            className="accordion" sx={{ border: '2px solid', borderColor: dtsError ? '#FF7B6F' : isErrorColor }}
            defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-control="about-you"
              id="about-you"
            >
              <Typography sx={{ padding: "0px 16px", color: "#666666" }}>
                Dying too soon
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", padding: "12px 16px" }}
              >
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                      {t("FourRisksDemiseMesasge")}
                    </FormLabel>
                    <Controller
                      name={`dts.protectYourSelf`}
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <RadioGroup
                            row
                            {...field}
                            {...register(`dts.protectYourSelf`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field?.value}
                          >
                            <FormControlLabel  {...register(`dts.protectYourSelf`)} value={'yes'} control={<CheckRadioButton />} label="Yes" />
                            <FormControlLabel  {...register(`dts.protectYourSelf`)} value={'no'} control={<CheckRadioButton />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ "& .MuiTextField-root": { width: {xs:'auto',sm:'50ch',md:"50ch"} }, marginTop: '15px' }}>
                  <FormControl disabled={disabledDts}>
                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                      {t("FourRisksInDeathQuestion")}
                    </FormLabel>
                    <Controller
                      control={control}
                      defaultValue={"" as any}
                      {...register(`dts.familyProvider`, {
                        required: "Please select option",
                        validate: {
                          sanatizeInput: (fieldValue) => {
                            return validateInput(fieldValue) || 'Please provide valid input'
                          }
                        }
                      })}
                      render={({ field }) => (<Select
                        sx={{ textAlign: "left", width: {xs:'auto',sm:'40% !important',md:'40% !important'} }}
                        variant="standard"

                        id="familyProvider"
                        label="Which currency will your inputs be in?"
                        error={!!errors?.dts?.familyProvider}
                        {...field}
                      >
                        <MenuItem value={1}>No one</MenuItem>
                        <MenuItem value={2}>Parent</MenuItem>
                        <MenuItem value={3}>Sibling</MenuItem>
                        <MenuItem value={4}>Spouse</MenuItem>
                        <MenuItem value={6}>Aunt/Uncle</MenuItem>
                        <MenuItem value={7}>Cousin</MenuItem>
                        <MenuItem value={8}>Others</MenuItem>
                      </Select>)} />
                    <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.familyProvider?.toString()}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: '15px' }}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FormControl disabled={disabledDts}>
                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                          {t("FourRisksInUponDeathQuestion")}
                        </FormLabel>
                        <Controller
                          defaultValue=""
                          {...register(`dts.familyUponDeath`, {
                            validate: {
                              sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                              }
                            }
                          })}
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <RadioGroup
                                row
                                {...field}
                                {...register(`dts.familyUponDeath`)}
                                onChange={(e) => {
                                  field.onChange(e);
                                  _setDtRates()
                                }}
                              >
                                <FormControlLabel  {...register(`dts.familyUponDeath`)} value={"REMAIN_IN_COUNTRY"} control={<CheckRadioButton disabled={disabledDts} />} label="Remain in the country" />
                                <FormControlLabel  {...register(`dts.familyUponDeath`)} value={"MOVE_HOME"} control={<CheckRadioButton disabled={disabledDts} />} label="Move to home country / another country" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.familyUponDeath?.toString()}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {fourRisk?.dts?.familyUponDeath === "MOVE_HOME" &&
                      <Grid item xs={12} md={6}>
                        <FormControl variant="standard" className="formElement">
                          <InputLabel id="demo-simple-select-filled-label">{t("PersonalDetailsCountryOfResident")}</InputLabel>
                          <Controller
                            control={control}
                            defaultValue=""
                            {...register(`dts.familyHomeCountry` as const, {
                              validate: {
                                sanatizeInput: (fieldValue) => {
                                  return validateInput(fieldValue) || 'Please provide valid input'
                                }
                              }
                            })}
                            render={({ field }) => (<Select
                              id="residentCountry"
                              error={!!errors?.dts?.familyHomeCountry}
                              disabled={disabledDts}
                              {...register(`dts.familyHomeCountry`,{
                                validate: {
                                  sanatizeInput: (fieldValue) => {
                                    return validateInput(fieldValue) || 'Please provide valid input'
                                  }
                                }
                              })}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                _setDtRates()
                              }}
                            >
                              {rateVisecountryList ?
                                rateVisecountryList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>)} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.familyHomeCountry?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: '15px' }}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FormControl disabled={disabledDts}>
                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }} disabled={disabledDts}>
                          {t("FourRisksOwnHome")}
                        </FormLabel>
                        <Controller
                          defaultValue=""
                          {...register(`dts.familyOwnProperty`, {
                            validate: {
                              noValue: (fieldValue) => {
                                if (disabledDts === false) {
                                  return (
                                    (fieldValue !== undefined && fieldValue !== '') || 'Please select an option'
                                  )
                                }
                              }
                            }
                          })}
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <RadioGroup
                                row
                                {...field}
                                {...register(`dts.familyOwnProperty`)}
                              >
                                <FormControlLabel  {...register(`dts.familyOwnProperty`)} value={"yes"} control={<CheckRadioButton disabled={disabledDts} />} label="Yes" />
                                <FormControlLabel  {...register(`dts.familyOwnProperty`)} value={"no"} control={<CheckRadioButton disabled={disabledDts} />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.familyOwnProperty?.message?.toString()}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {familyOwnProperty === 'yes' &&
                        <FormControl error={!!errors?.dts?.isHouseMortgage} disabled={disabledDts}>
                          <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                            {t("FourRisksHouseMortgage")}
                          </FormLabel>
                          <Controller
                            {...register(`dts.isHouseMortgage`, {
                              required: 'Please select an option',
                              disabled: fourRisk?.dts?.protectYourSelf === 'no',
                            })}
                            defaultValue={""}
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth disabled={disabledDts}>
                                <RadioGroup
                                  row
                                  {...field}
                                  {...register(`dts.isHouseMortgage`)}
                                >
                                  <FormControlLabel disabled={disabledDts} {...register(`dts.isHouseMortgage`)} value={'yes'} control={<CheckRadioButton />} label="Yes" />
                                  <FormControlLabel disabled={disabledDts}  {...register(`dts.isHouseMortgage`)} value={'no'} control={<CheckRadioButton />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.isHouseMortgage?.message?.toString()}</FormHelperText>
                        </FormControl>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width: {xs:'auto',sm:'50ch',md:"50ch"}  }, marginTop: '15px' }}
                >
                  <FormControl disabled={disabledDts}>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksAverageInflation")}
                    </FormLabel>

                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      placeholder="0.00%"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      disabled={disabledDts}
                      defaultValue=""
                      id="inflationRate"
                      {...register("responsibility.inflationRate", {
                        required: "Please enter a value",
                        validate: {
                          sanatizeInput: (fieldValue) => {
                            return validateInput(fieldValue) || 'Please provide valid input'
                          }
                        },
                        pattern: {
                          value: /^\d+\.?\d{0,2}$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 5,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: disabledDts ? 100 : (dayingTooSoonRates?.maxInflationRate)?.toFixed(2),
                          message: `Please enter value between ${Number(dayingTooSoonRates?.minInflationRate).toFixed(2)} to ${Number(dayingTooSoonRates?.maxInflationRate).toFixed(2)}`
                        },
                        min: {
                          value: disabledDts ? 0 : (dayingTooSoonRates?.minInflationRate)?.toFixed(2),
                          message: `Please enter value between ${Number(dayingTooSoonRates?.minInflationRate).toFixed(2)} to ${Number(dayingTooSoonRates?.maxInflationRate).toFixed(2)}`
                        }
                      }
                      )}
                      error={!!errors?.responsibility?.inflationRate}
                      helperText={errors?.responsibility?.inflationRate?.message}
                      InputProps={{
                        sx: {
                          "&::placeholder": {
                            color: "green"
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={(e) => handleIncrementInflationinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "green",
                              }}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleMinusInflationinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "red",
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width: {xs:'auto',sm:'50ch',md:"50ch"}}, marginTop: '15px' }}
                >
                  <FormControl disabled={disabledDts}>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksAverageInterestRate")}
                    </FormLabel>

                    <TextField
                      disabled={disabledDts}
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="0.00%"
                      defaultValue=""
                      id="interestRate"
                      {...register("responsibility.interestRate", {
                        required: "Please enter a value",
                        validate: {
                          sanatizeInput: (fieldValue) => {
                            return validateInput(fieldValue) || 'Please provide valid input'
                          }
                        },
                        pattern: {
                          value: /^\d+\.?\d{0,2}$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 5,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: disabledDts ? 100 : (dayingTooSoonRates?.maxInterestRate)?.toFixed(2),
                          message: `Please enter value between ${Number(dayingTooSoonRates?.minInterestRate).toFixed(2)} to ${Number(dayingTooSoonRates?.maxInterestRate).toFixed(2)}`
                        },
                        min: {
                          value: disabledDts ? 0 : (dayingTooSoonRates?.minInterestRate)?.toFixed(2),
                          message: `Please enter value between ${Number(dayingTooSoonRates?.minInterestRate).toFixed(2)} to ${Number(dayingTooSoonRates?.maxInterestRate).toFixed(2)}`
                        }
                      }
                      )}
                      error={!!errors?.responsibility?.interestRate}
                      helperText={errors?.responsibility?.interestRate?.message}
                      InputProps={{
                        sx: {
                          "&::placeholder": {
                            color: "green"
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => handleIncrementDtsinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "green",
                              }}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleMinusDtsinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "red",
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ textAlign: "left", padding: "0 16px", paddingTop: "40px" }}
                >
                  <Grid item xs={12} md={6}>
                    <FormControl variant="standard" className="formElement" disabled={disabledDts}>
                      <InputLabel id="GoalType-label">{t("FourRiskslifestyle")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={""}
                        {...register(`responsibility.familyLifeStyle` as const, {
                          validate: {
                            sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                            },
                            noValue: (fieldValue) => {
                              if (disabledDts === false) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') || 'Please select an option'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => (<Select
                          id="savingOptionId"
                          variant="standard"
                          disabled={disabledDts}
                          error={!!errors?.responsibility?.familyLifeStyle}
                          {...field}
                        >
                          <MenuItem value='frugal'>Frugal</MenuItem>
                          <MenuItem value='stressfree'>Stress-free</MenuItem>
                          <MenuItem value='high'>Nothing but the best</MenuItem>
                        </Select>)} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.responsibility?.familyLifeStyle?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ "& .MuiTextField-root": { width: {xs:'auto',sm:'50ch',md:"50ch"}}, marginTop: '15px' }}
                  >
                    <FormControl disabled={disabledDts}>
                      <FormLabel sx={{ color: "#005EB8" }}>
                        {t("FourRisksRegularExpensesSurviving")}
                      </FormLabel>
                      <TextField
                        variant="standard"
                        className="formElement"
                        type="number"
                        disabled={disabledDts}
                        placeholder="0.00"
                        defaultValue=""
                        sx={{ color: "#005EB8" }}
                        id="expectedRegularExpense"
                        {...register("responsibility.expectedRegularExpense", {
                          validate: {
                            sanatizeInput: (fieldValue) => {
                                return validateInput(fieldValue) || 'Please provide valid input'
                            },
                            noValue: (fieldValue) => {
                              if (disabledDts === false) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== null) || 'Please enter a value'
                                )
                              }
                            }
                          },
                          pattern: {
                            value: /^\d{1,8}(\.\d{0,2})?$/,
                            message: 'Please Enter valid decimal value.'
                          },
                          maxLength: {
                            value: 11,
                            message: "You have exceeded the character limit."
                          },
                          max: {
                            value: 100000000,
                            message: "Please enter valid amount"
                          },
                          min: {
                            value: 0,
                            message: "Please enter valid amount"
                          }
                        }
                        )}
                        error={!!errors?.responsibility?.expectedRegularExpense}
                        helperText={errors?.responsibility?.expectedRegularExpense?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {selectedCurrency ? selectedCurrency + " " : 'USD '}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "row", marginTop: '15px' }}
                  >
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel sx={{ color: "#005EB8" }}>
                        {t("FourRiskslargefutureExpenses")}{" "}
                      </FormLabel>
                      {
                        fields?.map((field, index) => {
                          return (
                            <Box key={field.id} className="accordion" sx={{ borderBottom: '1px solid #000', marginTop: '15px', marginBottom: '15px', width: '100%' }}>
                              <Grid container>
                                <Grid item xs={12} md={6}><Typography sx={{ padding: '25px 16px', textAlign: 'left' }}>Expense</Typography></Grid>
                                <Grid item xs={12} md={6} sx={{ textAlign: 'right' }} >
                                  <IconButton disabled={disabledDts} aria-label="delete" onClick={() => _deleteFutureExpense(index, fourRisk?.dts?.futureExpenses[index]?.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              <Grid container sx={{ textAlign: 'left', padding: '0 16px' }}>
                                <Grid container>
                                  <Grid item xs={12} md={6}>
                                    <FormControl variant="standard" className="formElement" disabled={disabledDts}>
                                      <InputLabel id="GoalType-label">Goal Type</InputLabel>
                                      <Controller
                                        control={control}
                                        defaultValue=""
                                        {...register(`dts.futureExpenses.${index}.type` as const, {
                                          required: 'Please select an option',
                                          
                                        })}

                                        render={({ field }) => (<Select
                                          label="Goal Type"
                                          id="GoalType"
                                          variant="standard"
                                          error={!!errors?.dts?.futureExpenses?.[index]?.type}
                                          {...field}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            getHowMuchNeed(index);
                                            let id = e.target.value;
                                            let depId = '';
                                            let isUniversity = '';
                                            if (id) {
                                              let value = e.target.value;
                                              let valueArray = value?.split("|")
                                              if (valueArray?.length > 0) {
                                                depId = valueArray[1]
                                                isUniversity = valueArray[0] ? valueArray[0] : ''
                                              }
                                            }
                                            setValue(`dts.futureExpenses.${index}.dependentId`, depId);
                                            if (isUniversity === "UNIVERSITY") {
                                              let DOB = getDependents.filter((x: any) => x.id == depId)[0]?.dob;
                                              let year = (new Date(DOB).getFullYear()) + 18
                                              let currentYear = new Date().getFullYear() + 5
                                              let targetYearValue = year >= currentYear ? year : currentYear;
                                              setValue(`dts.futureExpenses.${index}.targetYear`, targetYearValue);
                                            }
                                            field.onChange(e);
                                          }}
                                        >
                                          {getDependents.map((dependent: any, index: any) => (
                                            (dependent.relationId === 4) && (
                                              <MenuItem key={index} value={`UNIVERSITY|` + dependent.id} disabled={_getAlreadyAddedTypeFlag(`UNIVERSITY|` + dependent.id)} >
                                                University - {dependent.name}
                                              </MenuItem>
                                            )
                                          ))}
                                          <MenuItem value="UNIVERSITY">University</MenuItem>
                                          {getDependents.map((dependent: any, index: any) => (
                                            dependent.relationId === 4 && (
                                              <MenuItem key={index} value={`MARRIAGE|` + dependent.id} disabled={_getAlreadyAddedTypeFlag(`MARRIAGE|` + dependent.id)} >
                                                Marriage - {dependent.name}
                                              </MenuItem>
                                            )
                                          ))}
                                          <MenuItem value="MARRIAGE">Marriage</MenuItem>
                                          <MenuItem value="PROPERTY">Property</MenuItem>
                                          <MenuItem value="OTHERS">Others</MenuItem>
                                        </Select>
                                        )}
                                      />
                                      <FormHelperText sx={{ color: '#ea635c' }}>
                                        {(errors?.dts?.futureExpenses?.[0]?.type as any)?.message?.toString()}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      variant="standard"
                                      className="formElement"
                                      disabled={disabledDts}
                                      label="Description"
                                      type="string"
                                      id="description"
                                      {...register(`dts.futureExpenses.${index}.description` as const, {
                                        validate: {
                                          sanatizeInput: (fieldValue) => {
                                            return validateInput(fieldValue) || 'Please provide valid input'
                                          }
                                        }
                                      })}
                                      error={!!errors?.dts?.futureExpenses?.[index]?.description}
                                      helperText={errors?.dts?.futureExpenses?.[index]?.description?.message}
                                    />
                                  </Grid>
                                </Grid>

                                  {(fourRisk?.dts.futureExpenses[index]?.type === 'UNIVERSITY' || (fourRisk?.dts.futureExpenses[index]?.type)?.includes('UNIVERSITY') || (fourRisk?.dts.futureExpenses[index]?.type).includes('UNIVERSITY') || (fourRisk?.dts.futureExpenses[index]?.type) === 'PROPERTY') &&
                                    <Grid item xs={12} md={6} className="padding-top">
                                      <FormControl variant="standard" className="formElement" disabled={disabledDts}>
                                        <InputLabel id="country-label">{(fourRisk?.dts.futureExpenses[index]?.type === 'UNIVERSITY' || (fourRisk?.dts.futureExpenses[index]?.type)?.includes('UNIVERSITY')) ? 'Country of education' : 'Country'}</InputLabel>
                                        <Controller
                                          control={control}
                                          defaultValue={null}
                                          {...register(`dts.futureExpenses.${index}.countryCode` as const, {
                                            required: "Please select a value",
                                            validate: {
                                              sanatizeInput: (fieldValue) => {
                                                return validateInput(fieldValue) || 'Please provide valid input'
                                              }
                                            }
                                          })}
                                          render={({ field }) => (<Select
                                            label="country"
                                            id="country"
                                            variant="standard"
                                            disabled={disabledDts}
                                            error={!!errors?.dts?.futureExpenses?.[index]?.countryCode}
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              getHowMuchNeed(index);

                                              if (e.target.value == 'US') {
                                                setValue(`dts.futureExpenses.${index}.duration`, 4);
                                              } else if (e.target.value == 'GB') {
                                                setValue(`dts.futureExpenses.${index}.duration`, 3);
                                              } else {
                                                setValue(`dts.futureExpenses.${index}.duration`, 5)
                                              }
                                            }}
                                          >
                                            {
                                              (( fourRisk?.dts.futureExpenses[index]?.type === 'UNIVERSITY') || (fourRisk?.dts.futureExpenses[index]?.type)?.includes('UNIVERSITY')) ?
                                                (countryDialCodeList?.length > 0) ?
                                                  countryDialCodeList?.map((listElement: any, index: any) => {
                                                    if (listElement.isGoalEducationCountry === true)
                                                      return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                                  }) : ''
                                                :
                                                (countryDialCodeList?.length > 0) ?
                                                  countryDialCodeList?.map((listElement: any, index: any) => {
                                                    if (listElement.isGoalPropertyCountry === true)
                                                      return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                                  }) : ''
                                            }
                                          </Select>)} />
                                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.futureExpenses?.[index]?.countryCode?.message?.toString()}</FormHelperText>
                                      </FormControl>
                                    </Grid>
                                  }
                                   
                                  {(fourRisk?.dts.futureExpenses[index]?.type === 'UNIVERSITY' || (fourRisk?.dts.futureExpenses[index]?.type)?.includes('UNIVERSITY')) &&
                                    <Grid item xs={12} md={6} className="padding-top">
                                      <TextField
                                        variant="standard"
                                        className="formElement"
                                        label="No. of years education"
                                        disabled={disabledDts}
                                        type="number"
                                        onWheel={event => (document.activeElement as HTMLElement).blur()}
                                        id="duration"
                                        InputLabelProps={{ shrink: _getFlag(fourRisk?.dts.futureExpenses[index]?.duration) }}
                                        {...register(`dts.futureExpenses.${index}.duration` as const, {
                                          required: "Please enter years of Education",
                                          validate: {
                                            sanatizeInput: (fieldValue) => {
                                              return validateInput(fieldValue) || 'Please provide valid input'
                                            }
                                          },
                                          max: { value: 5, message: "Maximum value allowed is 5" },
                                          min: { value: 1, message: "Minimum value allowed is 1" }
                                        })}
                                        error={!!errors?.dts?.futureExpenses?.[index]?.duration}
                                        helperText={errors?.dts?.futureExpenses?.[index]?.duration?.message}

                                      />
                                    </Grid>
                                  }
                                  {fourRisk?.dts.futureExpenses[index]?.type === 'PROPERTY' &&
                                    <Grid item xs={12} md={6} className="padding-top">

                                      <FormControl variant="standard" className="formElement" disabled={disabledDts}>
                                        <InputLabel id="PriceRange-label">Price Range</InputLabel>
                                        <Controller
                                          control={control}
                                          defaultValue={""}
                                          {...register(`dts.futureExpenses.${index}.aspiration` as const, {
                                            required: "Please select a value",
                                            validate: {
                                              sanatizeInput: (fieldValue) => {
                                                return validateInput(fieldValue) || 'Please provide valid input'
                                              }
                                            }
                                          })}
                                          render={({ field }) => (<Select
                                            labelId="PriceRange-label"
                                            label="Price Range"
                                            type="string"
                                            disabled={disabledDts}
                                            id="PriceRange"
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              getHowMuchNeed(index);
                                            }}
                                            error={!!errors?.dts?.futureExpenses?.[index]?.aspiration}
                                          >
                                            <MenuItem value='LOW'>Low</MenuItem>
                                            <MenuItem value='MEDIUM'>Medium</MenuItem>
                                            <MenuItem value='HIGH'>High</MenuItem>
                                          </Select>)} />
                                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.futureExpenses?.[index]?.aspiration?.message?.toString()}</FormHelperText>
                                      </FormControl>
                                    </Grid>
                                  }
                                <Grid item xs={12} md={6} className="padding-top">
                                    <FormControl variant="standard" className="formElement" disabled={disabledDts}>
                                      <Controller
                                        control={control}
                                        {...register(`dts.futureExpenses.${index}.targetYear`, {
                                          validate: {
                                            noValue: (fieldValue) => {
                                              return (
                                                (fieldValue?.$d != 'Invalid Date' && fieldValue?.$d !== '' && fieldValue !== undefined) || 'Please enter a valid date'
                                              )
                                            },
                                            noFutureDate: (fieldValue) => {
                                              let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                                              let minDate = getMinTargetDate(fourRisk?.dts.futureExpenses[index]?.dependentId , fourRisk?.dts.futureExpenses[index]?.type)?.toString()
                                              let maxDate = getMaxTargetDate(fourRisk?.dts.futureExpenses[index]?.dependentId , fourRisk?.dts.futureExpenses[index]?.type)?.toString()
                                              let minDateYear = new Date(minDate ? minDate : new Date())?.getFullYear();
                                              let maxDateYear = new Date(maxDate ? maxDate : new Date())?.getFullYear();
                                              let enteredDate = (typeof dt === 'number') ? dt : new Date(dt).getFullYear();
                                              return (
                                                (enteredDate <= maxDateYear && enteredDate >= minDateYear) || 'Please enter a year between ' + minDateYear + " - " + maxDateYear
                                              )
                                            }
                                          }

                                        })
                                        }
                                        render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs} >
                                          <DatePicker
                                            disabled={disabledDts}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              getHowMuchNeed(index);
                                            }}
                                            minDate={getMinTargetDate(fourRisk?.dts.futureExpenses[index]?.dependentId, fourRisk?.dts.futureExpenses[index]?.type)}
                                            maxDate={getMaxTargetDate(fourRisk?.dts.futureExpenses[index]?.dependentId, fourRisk?.dts.futureExpenses[index]?.type)}
                                            value={formatedDate(field?.value)}
                                            label='When do you need it?' views={['year']} slotProps={{ textField: { variant: 'standard' } }} />
                                        </LocalizationProvider>)} />
                                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.futureExpenses?.[index]?.targetYear?.message?.toString()}</FormHelperText>
                                      {((fourRisk?.dts.futureExpenses[index]?.type)?.includes('UNIVERSITY|')) ? <FormHelperText sx={{ color: '#000' }}>* Target year has been adjusted to match Premium Payment Term</FormHelperText> : ''}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} className="padding-top">
                                    <TextField
                                      variant="standard"
                                      className="formElement"
                                      label="How much do you need"
                                      defaultValue={'' as any}
                                      disabled={disabledDts}
                                      InputLabelProps={{ shrink: _getFlag(fourRisk?.dts.futureExpenses[index]?.amount) }}
                                      type="string"
                                      id="amount"
                                      {...register(`dts.futureExpenses.${index}.amount` as const, {
                                        required: "Please enter a value",
                                        validate: {
                                          sanatizeInput: (fieldValue) => {
                                            return validateInput(fieldValue) || 'Please provide valid input'
                                          }
                                        },
                                        pattern: {
                                          value: /^\d+\.?\d{0,2}$/,
                                          message: 'Please Enter valid decimal value.'
                                        },
                                        maxLength: {
                                          value: 10,
                                          message: "You have exceeded the character limit."
                                        },
                                        max: {
                                          value: 100000000,
                                          message: "Please enter valid value."
                                        },
                                        min: {
                                          value: 1,
                                          message: "Please enter valid value."
                                        }
                                      })}
                                      error={!!errors?.dts?.futureExpenses?.[index]?.amount}
                                      helperText={errors?.dts?.futureExpenses?.[index]?.amount?.message}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            {selectedCurrency ? selectedCurrency + " " : 'USD '}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6} className="padding-top">
                                    <FormControl variant="standard" className="formElement" disabled={disabledDts}>
                                      <Typography id="priceRange-label" sx={{ marginBottom: '10px' }}>How important is this to you?</Typography>
                                      <Controller
                                        control={control}
                                        defaultValue={_getValue(fourRisk?.dts.futureExpenses[index]?.priority)}
                                        {...register(`dts.futureExpenses.${index}.priority` as const, {
                                          required: "Please select option"
                                        })}
                                        render={({ field }) => (
                                          <Slider
                                            disabled={disabledDts}
                                            defaultValue={_getValue(fourRisk?.dts.futureExpenses[index]?.priority)}
                                            aria-label="Temperature"
                                            getAriaValueText={_getLabels}
                                            valueLabelDisplay="off"
                                            marks={marks}
                                            onChange={(e) => field.onChange(e)}
                                            value={_getValue(field?.value)}
                                            step={10}
                                            min={10}
                                            max={50}
                                          />
                                        )} />
                                      <Typography sx={{ marginBottom: '10px' }}>{

                                        _getLabels(fourRisk?.dts.futureExpenses[index]?.priority)
                                      }</Typography>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                            </Box>
                          )
                        })
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ marginTop: "15px" }}>
                    {(!disabledDts && fields?.length < 5) &&
                      <Button
                        variant="outlined"
                        onClick={() => append({ "id": '', "type": "", "description": "", "priority": 10, "targetYear": '', "countryCode": null, "aspiration": "", "amount": undefined, "duration": 5, "dependentId": '' })}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        sx={{ border: "0 !important", padding: 0 }}
                      >
                        {t("FourRisksAddExpense")}
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl disabled={disabledDts}>
                      <FormLabel id="demo-row-radio-buttons-group-label" error={!!errors?.dts?.leaveMoneyBehind} sx={{ color: "#005EB8" }}>
                        {t("FourRisksleaveMoneyBehind")}
                      </FormLabel>
                      <Controller
                        {...register(`dts.leaveMoneyBehind`, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (disabledDts === false) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please select an option'
                                )
                              }
                            }
                          }
                        })}
                        defaultValue={""}
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            {...field}
                            {...register(`dts.leaveMoneyBehind`)}
                          >
                            <FormControlLabel disabled={disabledDts}  {...register(`dts.leaveMoneyBehind`)} value={'yes'} control={<CheckRadioButton disabled={disabledDts} />} label="Yes" />
                            <FormControlLabel disabled={disabledDts} {...register(`dts.leaveMoneyBehind`)} value={'no'} control={<CheckRadioButton disabled={disabledDts} />} label="No" />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.leaveMoneyBehind?.message?.toString()}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl disabled={disabledDts}>
                      <FormLabel id="inCaseOfDemiseValue-label" sx={{ color: "#005EB8" }}>
                        {t("FourRisksProtectYourself")}
                      </FormLabel>
                      <Controller
                        control={control}
                        name={`dts.inCaseOfDemise`}
                        defaultValue={_getValue(fourRisk?.dts?.inCaseOfDemise)}
                        render={({ field }) => (
                          <Slider
                            disabled={disabledDts}
                            sx={{ width: '65%' }}
                            aria-label="Temperature"
                            getAriaValueText={_getLabels}
                            valueLabelDisplay="off"
                            onChange={(e) => field.onChange(e)}
                            value={field?.value}
                            step={10}
                            marks={marks}
                            min={10}
                            max={50}
                          />
                        )} />
                    </FormControl>
                    <Typography sx={{ marginBottom: '10px' }}>{_getLabels(fourRisk?.dts?.inCaseOfDemise)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl error={!!errors?.dts?.preferredDeathPayment} disabled={disabledDts}>
                      <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                        {t("FourRisksCaseDeathQuestion")}
                      </FormLabel>
                      <Controller
                        defaultValue={""}
                        {...register(`dts.preferredDeathPayment`, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (disabledDts === false) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please select an option'
                                )
                              }
                            }
                          }
                        })}
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            disabled={disabledDts}
                            {...field}
                            {...register(`dts.preferredDeathPayment`)}
                          >
                            <FormControlLabel disabled={disabledDts} {...register(`dts.preferredDeathPayment`)} value={'MONTHLY_PAYOUT'} control={<CheckRadioButton disabled={disabledDts} />} label="Monthly pay-outs" />
                            <FormControlLabel disabled={disabledDts} {...register(`dts.preferredDeathPayment`)} value={'LUMPSUM_PAYOUT'} control={<CheckRadioButton disabled={disabledDts} />} label="Lumpsum Payout" />
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dts?.preferredDeathPayment?.message?.toString()}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* responsibilty section start  commented*/}

        {/* disablity and Critical illness section start */}
        <Grid style={{ marginTop: "40px", textAlign: "left" }}>
          <Accordion
            className="accordion" sx={{ border: '2px solid', borderColor: isErrorColor }}
            defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-control="about-you"
              id="about-you"
            >
              <Typography sx={{ padding: "0px 16px", color: "#666666" }}>{t("FinanceAdviceDisability")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", padding: "0 16px" }}
              >
                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: "#005EB8" }}>
                    {t("FourRisksOptimalCoverage")}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid style={{ marginTop: "40px", textAlign: "left" }}>
          <Accordion
            className="accordion" sx={{ border: '2px solid', borderColor: ciError ? '#FF7B6F' : isErrorColor }}
            defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-control="about-you"
              id="about-you"
            >
              <Typography sx={{ padding: "0px 16px", color: "#666666" }}>
                {t("FourRisksCriticalillness")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", padding: "0 16px" }}
              >
                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: "#ccc" }}>
                    {t("FourRisksAmountlifecoverage")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "#005EB8" }}>
                      {t("FourRisksUnfortunateEventQuestion")}
                    </FormLabel>
                    <Controller
                      {...register(`ci.ciProtection`, {
                        required: 'Please select an option'
                      })}
                      defaultValue={""}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          onChange={(event, value) => field.onChange(value)}
                          value={field?.value}
                        >
                          <FormControlLabel  {...register(`ci.ciProtection`)} value={'yes'} control={<CheckRadioButton />} label="Yes" />
                          <FormControlLabel  {...register(`ci.ciProtection`)} value={'no'} control={<CheckRadioButton />} label="No" />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText sx={{ color: '#ea635c' }}>{errors?.ci?.ciProtection?.message?.toString()}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width: {xs:'auto',sm:'50ch',md:"50ch"}} }}
                >
                  <FormControl disabled={disabledCi}>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksCriticaIllnessCover")}
                    </FormLabel>
                    <TextField
                      variant="standard"
                      disabled={disabledCi}
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="0.00"
                      defaultValue=""
                      sx={{ color: "#005EB8" }}
                      id="criticalIllnessLumpSum"
                      {...register("ci.criticalIllnessLumpSum", {
                        validate: {
                          noValue: (fieldValue: any) => {
                            if (disabledCi === false) {
                              return (
                                (fieldValue !== undefined && fieldValue !== '' && fieldValue !== null) || 'Please enter a value'
                              )
                            }
                          },
                          morethanZero: (fieldValue: any) => {
                            if (disabledCi === false) {
                              return (
                                (fieldValue >= 0) || 'Please enter valid amount'
                              )
                            }
                          }
                        },
                        pattern: {
                          value: /^\d{1,8}(\.\d{0,2})?$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 11,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: 100000000,
                          message: "Please enter valid amount"
                        },

                      }
                      )}
                      error={!!errors?.ci?.criticalIllnessLumpSum}
                      helperText={errors?.ci?.criticalIllnessLumpSum?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {selectedCurrency ? selectedCurrency + " " : 'USD '}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width:{xs:'auto',sm:'50ch',md:"50ch"}  } }}
                >
                  <FormControl disabled={disabledCi}>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksExpensesCovered")}
                    </FormLabel>
                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="00"
                      disabled={disabledCi}
                      sx={{ color: "#005EB8" }}
                      id="criticalIllnessDurationMonths"
                      {...register("ci.criticalIllnessDurationMonths", {
                        validate: {
                          noValue: (fieldValue: any) => {
                            if (disabledCi === false) {
                              return (
                                (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') || 'Please enter a value'
                              )
                            }
                          },
                          morethanZero: (fieldValue: any) => {
                            if (disabledCi === false) {
                              return (
                                (fieldValue >= 0) || 'Please enter valid month'
                              )
                            }
                          }
                        },
                        pattern: {
                          value: /^\d{1,3}(\.\d{0})?$/,
                          message: 'Please enter valid months.'
                        },
                        maxLength: {
                          value: 3,
                          message: "Please enter month between 0 to 720."
                        },
                        max: {
                          value: 720,
                          message: "Please enter month between 0 to 720."
                        }
                      }
                      )}
                      error={!!errors?.ci?.criticalIllnessDurationMonths}
                      helperText={errors?.ci?.criticalIllnessDurationMonths?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Typography sx={{ color: "#005EB8" }}>months</Typography>
                            <IconButton
                              aria-label="delete"
                              sx={{
                                color: "green",
                              }}
                              onClick={() => addMinExpenseCoveredByOne('plus')}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              sx={{
                                color: "red",
                              }}
                              onClick={() => addMinExpenseCoveredByOne('min')}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width: {xs:'auto',sm:'50ch',md:"50ch"}} }}
                >
                  <FormControl disabled={disabledCi}>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksExpensesPerMonth")}
                    </FormLabel>
                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="0.00"
                      disabled={disabledCi}
                      sx={{ color: "#005EB8" }}
                      id="criticalIllnessMonthlyAmount"
                      {...register("ci.criticalIllnessMonthlyAmount", {

                        validate: {

                          morethanZero: (fieldValue: any) => {
                            if (disabledCi === false) {
                              return (
                                (fieldValue >= 0) || 'Please enter valid amount'
                              )
                            }
                          }
                        },
                        pattern: {
                          value: /^\d{1,8}(\.\d{0,2})?$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 11,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: 100000000,
                          message: "Please enter valid amount"
                        }

                      }
                      )}
                      error={!!errors?.ci?.criticalIllnessMonthlyAmount}
                      helperText={errors?.ci?.criticalIllnessMonthlyAmount?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {selectedCurrency ? selectedCurrency + " " : 'USD '}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* end living too long section */}
        {/* living too long section */}
        <Grid style={{ marginTop: "40px", textAlign: "left" }}>
          <Accordion
            className="accordion" sx={{ border: '2px solid', borderColor: livingTooLongError ? '#FF7B6F' : isErrorColor }}
            defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-control="about-you"
              id="about-you"
            >
              <Typography sx={{ padding: "0px 16px", color: "#666666" }}>
                {t("FourRisksLivingToolong")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", padding: "0 16px" }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width:{xs:'auto',sm:'50ch',md:"50ch"}  }, marginTop: '15px' }}
                >
                  <FormControl>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksTargetRetirementAge")}
                    </FormLabel>
                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      id="targetRetirementAge"
                      {...register("livingTooLong.retirementAge", {
                        valueAsNumber: true,
                        required: "Please enter a value",
                        //   validate: {
                        //     noDecimalValue: (fieldValue) => {
                        //       if (fieldValue) {
                        //         let stringValue = fieldValue?.toString()
                        //         let pattern = /^\d{1,2}(\.\d{0,2})?$/;
                        //         return (
                        //           (pattern.test(stringValue)) || 'Please enter valid age.'
                        //         )
                        //       }
                        //     }
                        //   },
                        //   maxLength: {
                        //     value: 2,
                        //     message: "You have exceeded the character limit."
                        //   },
                        //   max: {
                        //     value: 98,
                        //     message: "Error: Maximum age is 98."
                        //   },
                        //   min: {
                        //     value: 18,
                        //     message: "Error: Minimum age is 18."
                        //   }
                        // }
                        // )}
                        validate: {
                          greaterThanUserAge: value =>
                            value && value > userAge ? true : "Retirement age must be greater than user age"
                          ,
                          noDecimalValue: fieldValue => {
                            if (fieldValue) {
                              let stringValue = fieldValue?.toString();
                              let pattern = /^\d{1,2}(\.\d{0,2})?$/;
                              return (
                                pattern.test(stringValue) || "Please enter a valid age."
                              );
                            }
                            return true;
                          },
                        },
                        maxLength: {
                          value: 2,
                          message: "You have exceeded the character limit.",
                        },
                        min: {
                          value: 18,
                          message: "Error: Minimum age is 18.",
                        },
                        max: {
                          value: 98,
                          message: "Error: Maximum age is 98.",
                        },
                      })}
                      error={!!errors?.livingTooLong?.retirementAge}
                      helperText={errors?.livingTooLong?.retirementAge?.message}
                      InputProps={{
                        sx: {
                          "&::placeholder": {
                            color: "green"
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="add"
                              sx={{
                                color: "green",
                              }}
                              onClick={() => addMinValueByOne('plus')}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              aria-label="minus"
                              sx={{
                                color: "red",
                              }}
                              onClick={() => addMinValueByOne('min')}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ "& .MuiInputBase-root": { width: {xs:'auto',sm:'44ch',md:"44ch"}}, marginTop: '15px' }}>
                  <FormControl variant="standard">
                    <FormLabel id="countryCode-label" sx={{ color: "#005EB8" }}>
                      {t("FourRisksStayingRetirement")}
                    </FormLabel>
                    <Controller
                      control={control}
                      defaultValue="AE"
                      {...register(`livingTooLong.countryCode` as const, {
                        validate: {
                          sanatizeInput: (fieldValue) => {
                            return validateInput(fieldValue) || 'Please provide valid input'
                          }
                        }
                      })}
                      render={({ field }) => (<Select
                        id="countryCode"
                        error={!!errors?.livingTooLong?.countryCode}
                        {...field}
                        onChange={((e) => {
                          field.onChange(e)
                          _getLTRates()
                        })}
                      >
                        {rateVisecountryList ?
                          rateVisecountryList?.map((listElement: any, index: number) => {
                            return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                          }) : ''
                        }
                      </Select>)} />
                    <FormHelperText sx={{ color: '#ea635c' }}>{errors?.livingTooLong?.countryCode?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width:{xs:'auto',sm:'50ch',md:"50ch"}  }, marginTop: '15px' }}
                >
                  <FormControl>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksInflationRateQuestion")}
                    </FormLabel>

                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="0.00%"
                      id="inflationRate"
                      {...register("livingTooLong.inflationRate", {
                        required: "Please enter a value",
                        pattern: {
                          value: /^\d+\.?\d{0,2}$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 5,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: (livingLongRates?.maxInflationRate)?.toFixed(2),
                          message: `Please enter value between ${Number(livingLongRates?.minInflationRate).toFixed(2)} to ${Number(livingLongRates?.maxInflationRate).toFixed(2)}`
                        },
                        min: {
                          value: (livingLongRates?.minInflationRate)?.toFixed(2),
                          message: `Please enter value between ${Number(livingLongRates?.minInflationRate).toFixed(2)} to ${Number(livingLongRates?.maxInflationRate).toFixed(2)}`
                        }
                      }
                      )}
                      error={!!errors?.livingTooLong?.inflationRate}
                      helperText={errors?.livingTooLong?.inflationRate?.message}
                      InputProps={{
                        sx: {
                          "&::placeholder": {
                            color: "green"
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => handleLTIncrementInflationinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "green",
                              }}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleLTMinusInflationinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "red",
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width:{xs:'auto',sm:'50ch',md:"50ch"}  }, marginTop: '15px' }}
                >
                  <FormControl>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksInterestRateQuestion")}
                    </FormLabel>

                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="0.00%"
                      id="interestRate"
                      {...register("livingTooLong.interestRate", {
                        required: "Please enter a value",
                        pattern: {
                          value: /^\d+\.?\d{0,2}$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 5,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: livingLongRates?.maxInterestRate,
                          message: `Please enter value between ${Number(livingLongRates?.minInterestRate).toFixed(2)} to ${Number(livingLongRates?.maxInterestRate).toFixed(2)}`
                        },
                        min: {
                          value: livingLongRates?.minInterestRate,
                          message: `Please enter value between ${Number(livingLongRates?.minInterestRate).toFixed(2)} to ${Number(livingLongRates?.maxInterestRate).toFixed(2)}`
                        }
                      })}
                      error={!!errors?.livingTooLong?.interestRate}
                      helperText={errors?.livingTooLong?.interestRate?.message}
                      InputProps={{
                        sx: {
                          "&::placeholder": {
                            color: "green"
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => handleIncrementLTinterestRate()}
                              aria-label="delete"
                              sx={{
                                color: "green",
                              }}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleMinusFamilyLTRateOfReturn()}
                              aria-label="delete"
                              sx={{
                                color: "red",
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sx={{ "& .MuiInputBase-root": { width:{xs:'auto',sm:'44ch',md:"44ch"}}, marginTop: '15px' }}>
                  <FormControl fullWidth>

                    <FormLabel id="countryCode-label" sx={{ color: "#005EB8" }}>
                      What type of lifestyle do you prefer during retirement?
                    </FormLabel>
                    <Controller
                      control={control}
                      defaultValue={"" as any}
                      {...register(`livingTooLong.familyLifeStyle` as const, {
                        required: "Please select a option",
                        validate: {
                          sanatizeInput: (fieldValue) => {
                            return validateInput(fieldValue) || 'Please provide valid input'
                          }
                        }
                      })}
                      render={({ field }) => (<Select
                        id="LivingLongFamilyLifeStyle"
                        variant="standard"
                        error={!!errors?.livingTooLong?.familyLifeStyle}
                        {...field}
                      >
                        <MenuItem value='frugal'>Frugal</MenuItem>
                        <MenuItem value='stressfree'>Stress-free</MenuItem>
                        <MenuItem value='high'>Nothing but the best</MenuItem>
                      </Select>)} />
                    <FormHelperText sx={{ color: '#ea635c' }}>{errors?.livingTooLong?.familyLifeStyle?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ "& .MuiTextField-root": { width:{xs:'auto',sm:'50ch',md:"50ch"}  }, marginTop: '15px' }}
                >
                  <FormControl>
                    <FormLabel sx={{ color: "#005EB8" }}>
                      {t("FourRisksAnnualexpenses")}
                    </FormLabel>
                    <TextField
                      variant="standard"
                      className="formElement"
                      type="number"
                      onWheel={event => (document.activeElement as HTMLElement).blur()}
                      placeholder="0.00"
                      defaultValue=""
                      sx={{ color: "#005EB8" }}
                      id="annualExpensesInYourRetirement"
                      {...register("livingTooLong.annualExpensesInYourRetirement", {
                        required: "Please enter a value",
                        pattern: {
                          value: /^\d{1,8}(\.\d{0,2})?$/,
                          message: 'Please Enter valid decimal value.'
                        },
                        maxLength: {
                          value: 11,
                          message: "You have exceeded the character limit."
                        },
                        max: {
                          value: 100000000,
                          message: "Please enter valid amount"
                        },
                        min: {
                          value: 1,
                          message: "Please enter valid amount"
                        }
                      }
                      )}
                      error={!!errors?.livingTooLong?.annualExpensesInYourRetirement}
                      helperText={errors?.livingTooLong?.annualExpensesInYourRetirement?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {selectedCurrency ? selectedCurrency + " " : 'USD '}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" className="formElement">
                    <Typography id="priceRange-label" sx={{ marginBottom: '10px' }}>{t("FourRisksImportantQuestion")}</Typography>
                    <Controller
                      control={control}
                      name={`livingTooLong.importantHaveEnoughMoney`}
                      defaultValue={_getValue(fourRisk?.livingTooLong?.importantHaveEnoughMoney)}
                      render={({ field }) => (
                        < Slider
                          aria-label="Temperature"
                          getAriaValueText={_getLabels}
                          valueLabelDisplay="off"

                          {...field}
                          step={10}
                          marks={marks}
                          min={10}
                          max={50}
                        />
                      )} />
                  </FormControl>
                  <Typography sx={{ marginBottom: '10px' }}>{_getLabels(fourRisk?.livingTooLong?.importantHaveEnoughMoney)}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* end living too long section */}

        {/* summary section start */}
        <Grid style={{ marginTop: "40px", textAlign: "left" }}>
          <Accordion
            style={{
              border: "1px solid rgba(157, 157, 157, 0.67)",
              boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
              borderRadius: "21px",
              backgroundColor: "#012169",
              color: "#fff",
              paddingBottom: "60px",
              backgroundImage: `url(${summaryImg})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right bottom",
              backgroundSize: "12%"
            }}
            expanded={expanded === 'panel2' || isClosedSession === true || isQuoteGenerated == true} onChange={handleChange('panel2')}
            defaultExpanded={isClosedSession === true || isQuoteGenerated == true ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-control="about-you"
              id="about-you"

            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ padding: "0 16px", textAlign: "left" }}>
                    {t("FinanceAdviceSummary")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                  <Button disabled={isClosedSession === true || isQuoteGenerated} variant="outlined" onClick={handleSubmit((data) => {

                    _onSubmit(data, 'summary');
                  }, (errors) => {
                    _onError();
                  })} startIcon={<LoopIcon />} sx={{ border: "1px solid #fff", color: "#fff", marginRight: "40px", 'pointerEvents': disableUpdate ? 'none' : 'auto' }}>
                    {" "}
                    {t("FinanceAdviceUpdate")}{" "}
                  </Button>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={10}
                sx={{ textAlign: "left", padding: "12px 16px" }}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      borderTop: "5px solid #21AFE5",
                      color: "#21AFE5",
                      paddingTop: "5px",
                      fontWeight: 600,
                    }}
                  >
                    {t("FourRisksDyingTooSoon")}
                  </Typography>
                  <Grid
                    sx={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography>{t("FourRisksExpectedLivingExpenses")}</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: 'right' }}>
                      <Typography> {selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.dyingTooSoon?.expectedLivingExpense ? (Math.round(fourRiskSummaryDetails?.dyingTooSoon?.expectedLivingExpense * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>

                      {fourRiskSummaryDetails?.dyingTooSoon?.lifeStyle &&
                        <Typography sx={{ color: "#21AFE5", fontSize: "13px" }}>
                          {fourRiskSummaryDetails?.dyingTooSoon?.lifeStyle}
                        </Typography>
                      }
                      <Typography sx={{ marginTop: "10px" }}>
                        {t("FourRisksLargeFutureExpenses")}
                      </Typography>
                    </Grid>

                  </Grid>
                  {fourRiskSummaryDetails?.dyingTooSoon?.largeFutureExpenses?.length > 0 &&
                    fourRiskSummaryDetails?.dyingTooSoon?.largeFutureExpenses?.map((element: any, index: number) => {
                      return (<Grid key={index}
                        sx={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0px",
                        }}
                      >
                        <Grid>
                          <Typography sx={{ fontWeight: 500 }}>
                            {element?.name}{getDependents.filter((x: any) => x.id == element.dependentId).length > 0 ? "-" + getDependents.filter((x: any) => x.id == element.dependentId)[0]?.name : ""}{element?.description ? "(" + element.description + ")" : ''}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography sx={{ fontWeight: 500 }}>
                            {selectedCurrency ? selectedCurrency + " " : 'USD '} {element.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </Typography>
                        </Grid>
                      </Grid>)
                    })
                  }
                  <Grid
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "3px solid #21AFE5",
                      borderBottom: "1px solid #21AFE5",
                      padding: "10px 0px",
                    }}
                  >
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {t("FourRisksLifeProtectionNeeds")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.dyingTooSoon?.lifeProtectionNeeds ? (Math.round(fourRiskSummaryDetails?.dyingTooSoon?.lifeProtectionNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        borderTop: "5px solid #DBD3BD",
                        color: "#21AFE5",
                        paddingTop: "5px",
                        fontWeight: 600,
                        marginTop: "40px",
                      }}
                    >
                      {t("FourRisksCriticalillness")}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography>
                        {t("FourRisksExpectedlivingExpenses")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography> {selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.criticalIllness?.expectedLivingExpense && (Math.round(fourRiskSummaryDetails?.criticalIllness?.expectedLivingExpense * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography>{t("FourRisksLumpSumMedica")}</Typography>
                    </Grid>
                    <Grid>
                      <Typography>{selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.criticalIllness?.lumpSumMedicalCost ? (Math.round(fourRiskSummaryDetails?.criticalIllness?.lumpSumMedicalCost * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography>{t("FourRisksLengthIncomeReplacement")}</Typography>
                    </Grid>
                    <Grid>
                      <Typography>{fourRiskSummaryDetails?.criticalIllness?.lengthOfIncomeReplacement ? (Math.round(fourRiskSummaryDetails?.criticalIllness?.lengthOfIncomeReplacement * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "3px solid #21AFE5",
                      borderBottom: "1px solid #21AFE5",
                      padding: "10px 0px",
                    }}
                  >
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {t("FourRisksCriticalIllnessNeeds")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.criticalIllness?.criticalIllnessNeeds ? (Math.round(fourRiskSummaryDetails?.criticalIllness?.criticalIllnessNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} sx={{ paddingRight: "30px" }}>
                  <Typography
                    sx={{
                      borderTop: "5px solid #22BDB9",
                      color: "#21AFE5",
                      paddingTop: "5px",
                      fontWeight: 600,
                    }}
                  >
                    {t("FinanceAdviceDisability")}
                  </Typography>
                  <Grid sx={{ marginTop: "10px" }}>
                    <Grid>
                      <Typography>
                        {" "}
                        {t("FourRisksDisabilityNeedsLifeProtection")}.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "38px",
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "1px solid  #21AFE5",
                      borderBottom: "3px solid  #21AFE5",
                      padding: "10px 0px",
                    }}
                  >
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {t("FourRisksDisabilityNeeds")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.disability?.amount ? (Math.round(fourRiskSummaryDetails?.disability?.amount * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        borderTop: "5px solid #7371D2",
                        color: "#21AFE5",
                        paddingTop: "5px",
                        fontWeight: 600,
                        marginTop: "40px",
                      }}
                    >
                      {t("FourRisksLivingToolong")}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography>
                        {t("FourRisksAnnualLivingExpensesAge")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography>{selectedCurrency ? selectedCurrency + " " : 'USD '} {fourRiskSummaryDetails?.livingTooLong?.annualLivingExpenseAtAgeOfRetirement ? (Math.round(fourRiskSummaryDetails?.livingTooLong?.annualLivingExpenseAtAgeOfRetirement * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Typography sx={{ color: "#21AFE5", fontSize: "13px" }}>
                      {fourRiskSummaryDetails?.dyingTooSoon?.lifeStyle && fourRiskSummaryDetails?.dyingTooSoon?.lifeStyle}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography>{t("FourRisksNumberYearsRetirement")}</Typography>
                      <Typography>
                        {" "}
                        ({t("FourRisksLifeExpectancy")})
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography>{fourRiskSummaryDetails?.livingTooLong?.noOfYearsInRetirement ? fourRiskSummaryDetails?.livingTooLong?.noOfYearsInRetirement : '0'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "1px solid #21AFE5",
                      borderBottom: "3px solid #21AFE5",
                      padding: "10px 0px",
                    }}
                  >
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {t("FourRisksRetirementNeeds")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography sx={{ fontWeight: 600 }}>
                        {selectedCurrency ? selectedCurrency + " " : 'USD '}   {fourRiskSummaryDetails?.livingTooLong?.retirementNeeds ? (Math.round(fourRiskSummaryDetails?.livingTooLong?.retirementNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "25px" }}>
          <Button
            disabled={isClosedSession === true || isQuoteGenerated}
            type="submit"
            startIcon={<ArrowBackIcon />}
            variant="contained"
            sx={{ background: "#005EB8", float: "left", width: '10%' }}
            onClick={handleSubmit((data) => {
              _onSubmit(data, 'prev');
            }, (errors) => {
              _onError();
            })}
          >
            {t("FinanceAdviceBack")}
          </Button>
          <Button
            disabled={isClosedSession === true || isQuoteGenerated}
            type="submit"
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            sx={{ background: "#005EB8", float: "right", width: '10%' }}
            onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })}
          >
            {t("FinanceAdviceNext")}
          </Button>
          <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
            _onSubmit(data, 'dashboard');
          }, (errors) => {
            _onError();
          })} ref={submitRef} sx={{ display: 'none' }}>
            {t("FinanceAdviceNext")}
          </Button>
        </Grid>
      </form>
      <DevTool control={control} />
      <ToastContainer />
      <Dialog
        open={open}
      >
        <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '130px', background: '#f6f9f8' }}>
          <Typography sx={{ color: '#4c433d', fontSize: '16px', fontWeight: 500, textAlign: 'left', margin: '10px 0' }}>Dear User, You have not entered Education expense for all the child.</Typography>
          <Box sx={{ textAlign: 'right' }}>

            <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={handleClose} autoFocus>
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default FourRisksComponent;
